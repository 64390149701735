import React from "react";
import { Link } from "react-router-dom";
import "../Css/NewsItem.css"

export default function NewsItem(props) {
  let {title, introduction, imageUrl, publishedAt, id} = props;

  const saveId = ()=>{
    localStorage.setItem('fullBlogId', id);
    window.scrollTo(0, 0);
  }
  return (
    <div>
      <div className="card my-3">
        <img src={imageUrl} className="card-img-top" alt="Blog Icon" />
        <div className="card-body">
          <h5 style={{fontFamily: "Poppins", fontWeight: "bold"}} className="card-title">{title.length > 50 ? `${title.slice(0, 50)}...` : title}</h5>
          <p className="card-text" style={{fontFamily: "Poppins"}}>
          {introduction.length > 50 ? `${introduction.slice(0, 75)}...` : introduction}
          </p>
           <p className="card-text">
           <strong>Published On:</strong> {publishedAt}
          </p>
          <Link rel="noreferrer" to={`/news/${id}`} onClick={saveId} className="btn btn-md btn-success">
            Read More
          </Link>
        </div>
      </div>
    </div>
  );
}