import React, { useState } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  useNavigate,
  useLocation,
} from "react-router-dom";
import "./App.css"
import Footer from "./Components/Footer";
import Home from "./Components/Home";
import Navbar from "./Components/Navbar";
import News from "./Components/News";
import About from "./Components/About";
import Contact from "./Components/Contact";
import PrivacyPolicy from "./Components/PrivacyPolicy";
import FullBlog from "./Components/FullBlog";
import DeleteAccount from "./Components/DeleteAccount";
import DeleteAccountEBL from "./Components/DeleteAccountEBL";

function App() {
  return (
    <BrowserRouter>
      <AppContent />
    </BrowserRouter>
  );
}

function AppContent() {
  let location = useLocation();
  const [deleteAccount, setIsDeleteAccount] = useState(true);

  useState(() => {
    if (location.pathname === "/ebr/delete-account" || location.pathname === "/ebl/delete-account") {
      setIsDeleteAccount(false);
    }
  }, [location]);

  return (
    <div className="d-flex flex-column min-vh-100">
      {deleteAccount && <Navbar />}
      <div className="container flex-grow-1" style={{ marginTop: "95px" }}>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/news" element={<News />} />
          <Route exact path="/about" element={<About />} />
          <Route exact path="/contact" element={<Contact />} />
          <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route exact path="/news/:newsId" element={<FullBlog />} />
          <Route exact path="/ebr/delete-account" element={<DeleteAccount />} />
          <Route exact path="/ebl/delete-account" element={<DeleteAccountEBL />} />
        </Routes>
      </div>

      {deleteAccount && (
        <footer className="footer mt-auto py-3 bg-light">
          <Footer />
        </footer>
      )}
    </div>
  );
}

export default App;
