import React, { useEffect, useState } from "react";

export default function About() {
  const [hasScrolled, setHasScrolled] = useState(false);

  useEffect(()=>{
    if (!hasScrolled) {
      // Scroll to the top of the page when the component mounts
      window.scrollTo(0, 0);
      setHasScrolled(true);
    }

    document.title = `Crypto Prediction - About Us`;
    // Optionally, we can reset the title when the component is unmounted
    return () => {
      document.title = "Crypto Price Prediction - Crypto Currency Prediction for Tomorrow Prices - Crypto News Daily - Bitcoin Price Tomorrow, Ethereum Price Tomorrow, Dogecoin Price Tomorrow, BNB Price Tomorrow, Thether Price Tomorrow or USDT Price Tomorrow, Cardano Price Tomorrow, Solana Price Tomorrow, XRP Price Tomorrow, Polkadot Price Tomorrow, USD Coin Price Tomorrow and much more...";
    };

  }, [hasScrolled])
  return (
    <div>
      <header>
        <h1>About CryptoPredicts</h1>
      </header>

      <section>
        <p>
          Welcome to CryptoPredicts, where the future of cryptocurrency meets
          insightful predictions. Our platform is your go-to source for staying
          ahead in the ever-evolving world of digital assets. At CryptoPredicts,
          we believe that knowledge is power, and our mission is to empower
          users with expert insights, thoughtful analysis, and a deep
          understanding of the cryptocurrency landscape.
        </p>
      </section>

      <section>
        <h2>Our Mission</h2>
        <p>
          Our primary mission is to guide crypto enthusiasts, traders, and
          investors through the intricacies of the crypto market. We strive to
          provide predictions that go beyond the surface, backed by thorough
          research, data analysis, and the collective expertise of our dedicated
          team. Whether you're a seasoned trader or a newcomer to the crypto
          space, CryptoPredicts is here to assist you in making informed
          decisions.
        </p>
      </section>

      <section>
        <h2>What Sets Us Apart</h2>
        <p>
          What makes CryptoPredicts stand out is our commitment to excellence
          and user-centric approach. Here's what sets us apart:
        </p>
        <ul>
          <li>
            <strong>Expertise:</strong> Our team comprises passionate crypto
            enthusiasts and experts with a deep understanding of blockchain
            technology and market dynamics.
          </li>
          <li>
            <strong>Transparency:</strong> We believe in transparent
            communication. Our predictions are not just numbers; they come with
            a comprehensive analysis, allowing users to understand the reasoning
            behind our insights.
          </li>
          <li>
            <strong>Education:</strong> We go beyond predictions by offering
            educational content. Understanding the fundamentals of blockchain,
            cryptocurrencies, and market trends is essential, and we're here to
            provide the knowledge you need.
          </li>
        </ul>
      </section>

      <section>
        <h2>Disclaimer</h2>
        <p>
          While we are committed to delivering accurate and insightful
          predictions, it's crucial to acknowledge the inherent volatility of
          cryptocurrency markets. Our predictions are based on diligent analysis
          and expert knowledge, but they should not be considered as financial
          advice. Cryptocurrency investments carry risks, and users are
          encouraged to conduct their own research and consider their risk
          tolerance before making any financial decisions based on our
          predictions.
        </p>
      </section>
    </div>
  );
}
