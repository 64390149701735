import React, { useEffect, useState } from "react";
import { HelmetProvider } from "react-helmet-async";
import { useParams } from "react-router-dom";
import Spinner from "./Spinner";
import placeholder from "../Images/blog_sample.jpg";
import NewsItem from "./NewsItem";
import "../Css/FullBlog.css";

const FullBlog = () => {
  const { newsId } = useParams();
  const [blogData, setBlogData] = useState({});
  const [latestBlogs, setLatestBlogs] = useState([]);
  const [loader, setLoader] = useState(true);
  const [blogLoader, setBlogLoader] = useState(true);
  const [date, setDate] = useState("Loading...");

  const sortedBlogs = latestBlogs.sort((a, b) => new Date(b.date) - new Date(a.date));
  const getBlogData = async () => {
    try {
      const response = await fetch(`https://cryptopredicts.live:1000/api/v1/news/getSingleNews/${newsId}`);
      const data = await response.json();
      setBlogData(data);
      setLoader(false);
      setDate(formatDate(data.date));

      document.title = data.title + " - Crypto Prediction";
    } catch (error) {
      console.error("Error fetching blog data:", error);
    }
  };

  const getLatestBlogs = async () => {
    try {
      const response = await fetch("https://cryptopredicts.live:1000/api/v1/news/getNews");
      const data = await response.json();
      setLatestBlogs(data);
      setBlogLoader(false);
    } catch (error) {
      console.error("Error fetching latest blogs:", error);
    }
  };

  const formatDate = (date) => {
    const options = {
      day: "numeric",
      month: "long",
      year: "numeric",
    };

    return new Date(date).toLocaleDateString("en-GB", options);
  };

  const tomorrowDate = (date) => {
    const originalDate = new Date(date);
    const nextDay = new Date(originalDate);
    nextDay.setDate(originalDate.getDate() + 1);

    const options = {
      day: "numeric",
      month: "long",
      year: "numeric",
    };

    return nextDay.toLocaleDateString("en-GB", options);
  };

  useEffect(() => {
    getBlogData();
    getLatestBlogs();

    return () => {
      document.title = "Crypto Price Prediction - Crypto Currency Prediction for Tomorrow Prices - Crypto News Daily - Bitcoin Price Tomorrow, Ethereum Price Tomorrow, Dogecoin Price Tomorrow, BNB Price Tomorrow, Thether Price Tomorrow or USDT Price Tomorrow, Cardano Price Tomorrow, Solana Price Tomorrow, XRP Price Tomorrow, Polkadot Price Tomorrow, USD Coin Price Tomorrow and much more...";
    };
  }, [newsId]);

  if (loader) {
    return <Spinner />;
  }

  return (
    <HelmetProvider>
      <div className="container my-3">
        <h1 className="mb-4">
          {blogData.title} ({tomorrowDate(blogData.date)}) Is it Gonna Rise or Not?
        </h1>
        <p>
            <strong>Published On:</strong> {date}
          </p>
        <div className="text-center">
          <img src={blogData.imageLink === "" ? placeholder : blogData.imageLink} className="img-fluid rounded" alt="Blog Cover" />
        </div>

        <div className="mt-4">
          <h2 style={{ color: "#36454F", marginTop: "30px" }}>Introduction:</h2>
          <p style={{ marginTop: "15px" }}>{Article(blogData.introduction)}</p>

          <h2 style={{ color: "#36454F", marginTop: "30px" }}>Previous Prices:</h2>
          <p style={{ marginTop: "15px" }}>{Article(blogData.previousPrices)}</p>

          <img src={blogData.graph} className="img-fluid rounded" alt="Previous Prices Graph" />

          <h2 style={{ color: "#36454F", marginTop: "30px" }}>Market Demand:</h2>
          <p style={{ marginTop: "15px" }}>{Article(blogData.marketDemand)}</p>

          <h2 style={{ color: "#36454F", marginTop: "30px" }}>Prediction:</h2>
          <p style={{ marginTop: "15px" }}>{Article(blogData.prediction)}</p>

          <h2 style={{ color: "#36454F", marginTop: "30px" }}>Conclusion:</h2>
          <p style={{ marginTop: "15px" }}>{Article(blogData.conclusion)}</p>

          <h2 style={{ color: "#36454F", marginTop: "30px" }}>Disclaimer:</h2>
          <p style={{ marginTop: "15px" }}>{Article(blogData.disclaimer)}</p>
        </div>
      </div>
      <br></br>

      <h1 className="text-center my-3" style={{ color: "green" }}>
        Read More Latest Articles & Blogs:
      </h1>
      {!loader && blogLoader ? <Spinner/> : ""}
      <div className="container my-3">
        <div className="row">
          {sortedBlogs
            .filter((element) => element._id !== newsId)
            .slice(0, 15)
            .map((element) => (
              <div className="col-md-4 text-center" key={element._id}>
                <NewsItem
                  imageUrl={element.imageLink === "" ? placeholder : element.imageLink}
                  title={`${element.title} (${tomorrowDate(element.date)}) Is it Gonna Rise or Not?`}
                  introduction={element.introduction}
                  publishedAt={formatDate(element.date)}
                  id={element._id}
                />
              </div>
            ))}
        </div>
      </div>
    </HelmetProvider>
  );
};

const Article = (text) => {
  // Split the text into sentences
  const sentences = text.match(/[^.!?]+[.!?]+["')]*|\S+[^.!?]+["')]*|\S+[.!?]+["')]*|\S+/g) || [];

  // Combine sentences into paragraphs with a maximum of 75 words
  let paragraphs = [];
  let currentParagraph = '';

  sentences.forEach((sentence) => {
    const words = sentence.split(' ');
    if (currentParagraph.split(' ').length + words.length <= 75) {
      currentParagraph += sentence + ' ';
    } else {
      paragraphs.push(currentParagraph.trim());
      currentParagraph = sentence + ' ';
    }
  });

  // Add the last paragraph
  if (currentParagraph.trim() !== '') {
    paragraphs.push(currentParagraph.trim());
  }

  return (
    <div>
      {paragraphs.map((paragraph, index) => (
        <p key={index}>{paragraph}</p>
      ))}
    </div>
  );
};



export default FullBlog;
