import React, { useState } from "react";

export default function DeleteAccountEBL() {
    const [details, setDetails] = useState({
        email: "",
      });
      const [message, setMessage] = useState("Sending request, please wait...");
      const [display, setDisplay] = useState("none");
      const [alertType, setAlertType] = useState("alert-primary");
    
    
      const onChange = (e) => {
        const { name, value } = e.target;
        setDetails({ ...details, [name]: value });
      };
    
      const btnClick = async () => {
        setMessage("Sending request, please wait...");
        setDisplay("block");
        setAlertType("alert-primary");
    
        try{
          const url = "https://cryptopredicts.live:1000/api/v1/ebr/deleteAccount";
          let data = await fetch(url, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              email: details.email
            })
          });
    
          const response = await data.json();
          console.log(response);
          alert("Your account deletion request sent successfully. We will delete your account in 1 to 2 hours")
    
        } catch(e){
          console.log(e)
          setAlertType("alert-danger");
          alert("Some error occurred while sending account deletion request. Please check your connection and try again")
        }
    
      };
    
      const alert = (message) => {
        setMessage(`${message}`);
        setTimeout(() => {
          setDisplay("none");
        }, 8000);
      };
    
      return (
        <>
          <div className="mb-3 my-3">
            <h1 className="text-center">EBL Pakistan - Delete Account Request</h1>
            <div className="my-3">
              <label for="email" className="form-label">
                <strong>Enter your account email:</strong>
              </label>
              <input
                type="email"
                className="form-control"
                id="email"
                name="email"
                value={details.email}
                onChange={onChange}
                placeholder="name@example.com"
              />
            </div>
          </div>
          <button type="button" onClick={btnClick} className="btn btn-primary">
            Request Deletion
          </button>
          <div
              style={{ display: `${display}` }}
              class={`alert ${alertType} my-3`}
              role="alert"
            >
              {message}
            </div>
        </>
      );
}
