import React from "react";
import "../Css/Footer.css";

export default function Footer() {
  return (
    <div>
      <footer className="bg-dark text-white text-center py-3" style={{width: "100%"}}>
      <div className="container">
        <p className="mb-0">Copyright &copy; 2023 - 2024 cryptopredicts.live - All Rights Reserved.

</p>
      </div>
    </footer>
    </div>
  );
}
