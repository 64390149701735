import React, { useEffect, useState } from "react";
import "../Css/Home.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp, faArrowDown } from "@fortawesome/free-solid-svg-icons";

import placeholder from "../Images/blog_sample.jpg";
import NewsItem from "./NewsItem";
import Spinner from "./Spinner";
import NewsletterSubscription from "./NewsletterSubscription";
import { Link } from "react-router-dom";

export default function Home() {
  const [hasScrolled, setHasScrolled] = useState(false);
  const [blogs, setBlogs] = useState([]);
  const sortedBlogs = blogs.sort((a, b) => new Date(b.date) - new Date(a.date));
  const [loaded, setLoaded] = useState(true);

  /* .............. */
  const [PriceDate, setPriceDate] = useState("Loading Date...");
  const [bitcoinPriceToday, setBitcoinPriceToday] = useState("Loading...");
  const [bitcoinPriceTomorrow, setBitcoinPriceTomorrow] =
    useState("Loading...");
  const [isBitcoinUp, setIsBitcoinUp] = useState(false);
  const [ethereumPriceToday, setEthereumPriceToday] = useState("Loading...");
  const [ethereumPriceTomorrow, setEthereumPriceTomorrow] =
    useState("Loading...");
  const [isEthereumUp, setIsEthereumUp] = useState(false);
  const [thetherPriceToday, setThetherPriceToday] = useState("Loading...");
  const [thetherPriceTomorrow, setThetherPriceTomorrow] =
    useState("Loading...");
  const [isThetherUp, setIsThetherUp] = useState(false);
  const [solanaPriceToday, setSolanaPriceToday] = useState("Loading...");
  const [solanaPriceTomorrow, setSolanaPriceTomorrow] = useState("Loading...");
  const [isSolanaUp, setIsSolanaUp] = useState(false);
  const [bnbPriceToday, setBnbPriceToday] = useState("Loading...");
  const [bnbPriceTomorrow, setBnbPriceTomorrow] = useState("Loading...");
  const [isBnbUp, setIsBnbUp] = useState(false);
  const [xrpPriceToday, setXrpPriceToday] = useState("Loading...");
  const [xrpPriceTomorrow, setXrpPriceTomorrow] = useState("Loading...");
  const [isXrpUp, setIsXrpUp] = useState(false);
  const [usdCoinPriceToday, setUsdCoinPriceToday] = useState("Loading...");
  const [usdCoinPriceTomorrow, setUsdCoinPriceTomorrow] =
    useState("Loading...");
  const [isUsdCoinUp, setIsUsdCoinUp] = useState(false);
  const [cardonaPriceToday, setCardonaPriceToday] = useState("Loading...");
  const [cardonaPriceTomorrow, setCardonaPriceTomorrow] =
    useState("Loading...");
  const [isCardonaUp, setIsCardonaUp] = useState(false);
  const [avalanchePriceToday, setAvalanchePriceToday] = useState("Loading...");
  const [avalanchePriceTomorrow, setAvalanchePriceTomorrow] =
    useState("Loading...");
  const [isAvalancheUp, setIsAvalancheUp] = useState(false);
  const [dogeCoinPriceToday, setDogeCoinPriceToday] = useState("Loading...");
  const [dogeCoinPriceTomorrow, setDogeCoinPriceTomorrow] =
    useState("Loading...");
  const [isDogeCoinUp, setIsDogeCoinUp] = useState("Loading...");
  const [polkadotPriceToday, setPolkadotPriceToday] = useState("Loading...");
  const [polkadotPriceTomorrow, setPolkadotPriceTomorrow] =
    useState("Loading...");
  const [isPolkadotUp, setIsPolkadotUp] = useState(false);
  const [tronPriceToday, setTronPriceToday] = useState("Loading...");
  const [tronPriceTomorrow, setTronPriceTomorrow] = useState("Loading...");
  const [isTronUp, setIsTronUp] = useState("Loading...");
  const [polygonPriceToday, setPolygonPriceToday] = useState("Loading...");
  const [polygonPriceTomorrow, setPolygonPriceTomorrow] =
    useState("Loading...");
  const [isPolygonUp, setIsPolygonUp] = useState(false);
  const [chainlinkPriceToday, setChainlinkPriceToday] = useState("Loading...");
  const [chainlinkPriceTomorrow, setChainlinkPriceTomorrow] =
    useState("Loading...");
  const [isChainlinkUp, setIsChainlinkUp] = useState(false);
  const [toncoinPriceToday, setToncoinPriceToday] = useState("Loading...");
  const [toncoinPriceTomorrow, setToncoinPriceTomorrow] =
    useState("Loading...");
  const [isToncoinUp, setIsToncoinUp] = useState(false);
  const [wrappedBitcoinPriceToday, setWrappedBitcoinPriceToday] =
    useState("Loading...");
  const [wrappedBitcoinPriceTomorrow, setWrappedBitcoinPriceTomorrow] =
    useState("Loading...");
  const [isWrappedBitcoinUp, setIsWrappedBitcoinUp] = useState(false);
  const [shibaInuPriceToday, setShibaInuPriceToday] = useState("Loading...");
  const [shibaInuPriceTomorrow, setShibaInuPriceTomorrow] =
    useState("Loading...");
  const [isShibaInuUp, setIsShibaInuUp] = useState(false);

  const [litecoinPriceToday, setLitecoinPriceToday] = useState("Loading...");
  const [litecoinPriceTomorrow, setLitecoinPriceTomorrow] =
    useState("Loading...");
  const [isLitecoinUp, setIsLitecoinUp] = useState(false);

  const [daiPriceToday, setDaiPriceToday] = useState("Loading...");
  const [daiPriceTomorrow, setDaiPriceTomorrow] = useState("Loading...");
  const [isDaiUp, setIsDaiUp] = useState(false);

  const [bitcoinCashPriceToday, setBitcoinCashPriceToday] =
    useState("Loading...");
  const [bitcoinCashPriceTomorrow, setBitcoinCashPriceTomorrow] =
    useState("Loading...");

  const [isBitcoinCashUp, setIsBitcoinCashUp] = useState(false);
  const [cosmosPriceToday, setCosmosPriceToday] = useState("Loading...");
  const [cosmosPriceTomorrow, setCosmosPriceTomorrow] = useState("Loading...");
  const [isCosmosUp, setIsCosmosUp] = useState(false);

  const [uniswapPriceToday, setUniswapPriceToday] = useState("Loading...");
  const [uniswapPriceTomorrow, setUniswapPriceTomorrow] =
    useState("Loading...");
  const [isUniswapUp, setIsUniswapUp] = useState(false);

  const [nearProtocolPriceToday, setNearProtocolPriceToday] =
    useState("Loading...");
  const [nearProtocolPriceTomorrow, setNearProtocolPriceTomorrow] =
    useState("Loading...");
  const [isNearProtocolUp, setIsNearProtocolUp] = useState(false);

  const [internetProtocolPriceToday, setInternetProtocolPriceToday] =
    useState("Loading...");
  const [internetProtocolPriceTomorrow, setInternetProtocolPriceTomorrow] =
    useState("Loading...");
  const [isInternetProtocolUp, setIsInternetProtocolUp] = useState(false);

  const [unusSedLeoPriceToday, setUnusSedLeoPriceToday] =
    useState("Loading...");
  const [unusSedLeoPriceTomorrow, setUnusSedLeoPriceTomorrow] =
    useState("Loading...");
  const [isUnusSedLeoUp, setIsUnusSedLeoUp] = useState(false);

  const [stellerPriceToday, setStellerPriceToday] = useState("Loading...");
  const [stellerPriceTomorrow, setStellerPriceTomorrow] =
    useState("Loading...");
  const [isStellerUp, setIsStellerUp] = useState(false);

  const [okbPriceToday, setOkbPriceToday] = useState("Loading...");
  const [okbPriceTomorrow, setOkbPriceTomorrow] = useState("Loading...");
  const [isOkbUp, setIsOkbUp] = useState(false);
  const [injectivePriceToday, setInjectivePriceToday] = useState("Loading...");
  const [injectivePriceTomorrow, setInjectivePriceTomorrow] =
    useState("Loading...");
  const [isInjectiveUp, setIsInjectiveUp] = useState(false);

  const [moneroPriceToday, setMoneroPriceToday] = useState("Loading...");
  const [moneroPriceTomorrow, setMoneroPriceTomorrow] = useState("Loading...");
  const [isMoneroUp, setIsMoneroUp] = useState(false);

  const [aptosPriceToday, setAptosPriceToday] = useState("Loading...");
  const [aptosPriceTomorrow, setAptosPriceTomorrow] = useState("Loading...");
  const [isAptosUp, setIsAptosUp] = useState(false);

  const [ethereumClassicPriceToday, setEthereumClassicPriceToday] =
    useState("Loading...");
  const [ethereumClassicPriceTomorrow, setEthereumClassicPriceTomorrow] =
    useState("Loading...");
  const [isEthereumClassicUp, setIsEthereumClassicUp] = useState(false);

  const [hederaPriceToday, setHederaPriceToday] = useState("Loading...");
  const [hederaPriceTomorrow, setHederaPriceTomorrow] = useState("Loading...");
  const [isHederaUp, setIsHederaUp] = useState(false);

  const [immutableXPriceToday, setImmutableXPriceToday] =
    useState("Loading...");
  const [immutableXPriceTomorrow, setImmutableXPriceTomorrow] =
    useState("Loading...");
  const [isImmutableXUp, setIsImmutableXUp] = useState(false);

  const [optimismPriceToday, setOptimismPriceToday] = useState("Loading...");
  const [optimismPriceTomorrow, setOptimismPriceTomorrow] =
    useState("Loading...");

  const [isOptimismUp, setIsOptimismUp] = useState(false);

  const [filecoinPriceToday, setFilecoinPriceToday] = useState("Loading...");
  const [filecoinPriceTomorrow, setFilecoinPriceTomorrow] =
    useState("Loading...");
  const [isFilecoinUp, setIsFilecoinUp] = useState(false);

  const [veChainPriceToday, setVeChainPriceToday] = useState("Loading...");
  const [veChainPriceTomorrow, setVeChainPriceTomorrow] =
    useState("Loading...");
  const [isVeChainUp, setIsVeChainUp] = useState(false);

  const [cronosPriceToday, setCronosPriceToday] = useState("Loading...");
  const [cronosPriceTomorrow, setCronosPriceTomorrow] = useState("Loading...");
  const [isCronosUp, setIsCronosUp] = useState(false);

  const [trueUsdPriceToday, setTrueUsdPriceToday] = useState("Loading...");
  const [trueUsdPriceTomorrow, setTrueUsdPriceTomorrow] =
    useState("Loading...");
  const [isTrueUsdUp, setIsTrueUsdUp] = useState(false);

  const [kaspaPriceToday, setKaspaPriceToday] = useState("Loading...");
  const [kaspaPriceTomorrow, setKaspaPriceTomorrow] = useState("Loading...");
  const [isKaspaUp, setIsKaspaUp] = useState(false);

  const [lidoDaoTokenPriceToday, setLidoDaoTokenPriceToday] =
    useState("Loading...");
  const [lidoDaoTokenPriceTomorrow, setLidoDaoTokenPriceTomorrow] =
    useState("Loading...");
  const [isLidoDaoTokenUp, setIsLidoDaoTokenUp] = useState(false);

  const [stacksPriceToday, setStacksPriceToday] = useState("Loading...");
  const [stacksPriceTomorrow, setStacksPriceTomorrow] = useState("Loading...");
  const [isStacksUp, setIsStacksUp] = useState(false);

  const [celestiaPriceToday, setCelestiaPriceToday] = useState("Loading...");
  const [celestiaPriceTomorrow, setCelestiaPriceTomorrow] =
    useState("Loading...");
  const [isCelestiaUp, setIsCelestiaUp] = useState(false);

  const [algorandPriceToday, setAlgorandPriceToday] = useState("Loading...");
  const [algorandPriceTomorrow, setAlgorandPriceTomorrow] =
    useState("Loading...");
  const [isAlgorandUp, setIsAlgorandUp] = useState(false);

  const [mantlePriceToday, setMantlePriceToday] = useState("Loading...");
  const [mantlePriceTomorrow, setMantlePriceTomorrow] = useState("Loading...");
  const [isMantleUp, setIsMantleUp] = useState(false);

  const [thorChainPriceToday, setThorChainPriceToday] = useState("Loading...");
  const [thorChainPriceTomorrow, setThorChainPriceTomorrow] =
    useState("Loading...");
  const [isThorChainUp, setIsThorChainUp] = useState(false);

  const [multiversXPriceToday, setMultiversXPriceToday] =
    useState("Loading...");
  const [multiversXPriceTomorrow, setMultiversXPriceTomorrow] =
    useState("Loading...");
  const [isMultiversXUp, setIsMultiversXUp] = useState(false);

  const [renderTokenPriceToday, setRenderTokenPriceToday] =
    useState("Loading...");
  const [renderTokenPriceTomorrow, setRenderTokenPriceTomorrow] =
    useState("Loading...");
  const [isRenderTokenUp, setIsRenderTokenUp] = useState(false);

  const [theGraphPriceToday, setTheGraphPriceToday] = useState("Loading...");
  const [theGraphPriceTomorrow, setTheGraphPriceTomorrow] =
    useState("Loading...");
  const [isTheGraphUp, setIsTheGraphUp] = useState(false);

  const [firstDigitalUsdPriceToday, setFirstDigitalUsdPriceToday] =
    useState("Loading...");
  const [firstDigitalUsdPriceTomorrow, setFirstDigitalUsdPriceTomorrow] =
    useState("Loading...");
  const [isFirstDigitalUsdUp, setIsFirstDigitalUsdUp] = useState(false);

  const [quantPriceToday, setQuantPriceToday] = useState("Loading...");
  const [quantPriceTomorrow, setQuantPriceTomorrow] = useState("Loading...");
  const [isQuantUp, setIsQuantUp] = useState(false);

  const getPrices = async () => {
    const getPriceApiUrl =
      "https://cryptopredicts.live:1000/api/v1/price/getPrices";

    try {
      let data = await fetch(getPriceApiUrl);
      let parsedData = await data.json();
      console.log(parsedData[0].PriceDate);
      setPriceDate(parsedData[0].PriceDate);
      setBitcoinPriceToday(parsedData[0].bitcoinPriceToday);
      setBitcoinPriceTomorrow(parsedData[0].bitcoinPriceTomorrow);
      setIsBitcoinUp(parsedData[0].isBitcoinUp);
      setEthereumPriceToday(parsedData[0].ethereumPriceToday);
      setEthereumPriceTomorrow(parsedData[0].ethereumPriceTomorrow);
      setIsEthereumUp(parsedData[0].isEthereumUp);
      setThetherPriceToday(parsedData[0].thetherPriceToday);
      setThetherPriceTomorrow(parsedData[0].thetherPriceTomorrow);
      setIsThetherUp(parsedData[0].isThetherUp);
      setSolanaPriceToday(parsedData[0].solanaPriceToday);
      setSolanaPriceTomorrow(parsedData[0].solanaPriceTomorrow);
      setIsSolanaUp(parsedData[0].isSolanaUp);
      setBnbPriceToday(parsedData[0].bnbPriceToday);
      setBnbPriceTomorrow(parsedData[0].bnbPriceTomorrow);
      setIsBnbUp(parsedData[0].isBnbUp);
      setXrpPriceToday(parsedData[0].xrpPriceToday);
      setXrpPriceTomorrow(parsedData[0].xrpPriceTomorrow);
      setIsXrpUp(parsedData[0].isXrpUp);
      setUsdCoinPriceToday(parsedData[0].usdCoinPriceToday);
      setUsdCoinPriceTomorrow(parsedData[0].usdCoinPriceTomorrow);
      setIsUsdCoinUp(parsedData[0].isUsdCoinUp);
      setCardonaPriceToday(parsedData[0].cardonaPriceToday);
      setCardonaPriceTomorrow(parsedData[0].cardonaPriceTomorrow);
      setIsCardonaUp(parsedData[0].isCardonaUp);
      setAvalanchePriceToday(parsedData[0].avalanchePriceToday);
      setAvalanchePriceTomorrow(parsedData[0].avalanchePriceTomorrow);
      setIsAvalancheUp(parsedData[0].isAvalancheUp);
      setDogeCoinPriceToday(parsedData[0].dogeCoinPriceToday);
      setDogeCoinPriceTomorrow(parsedData[0].dogeCoinPriceTomorrow);
      setIsDogeCoinUp(parsedData[0].isDogeCoinUp);
      setPolkadotPriceToday(parsedData[0].polkadotPriceToday);
      setPolkadotPriceTomorrow(parsedData[0].polkadotPriceTomorrow);
      setIsPolkadotUp(parsedData[0].isPolkadotUp);
      setTronPriceToday(parsedData[0].tronPriceToday);
      setTronPriceTomorrow(parsedData[0].tronPriceTomorrow);
      setIsTronUp(parsedData[0].isTronUp);
      setPolygonPriceToday(parsedData[0].polygonPriceToday);
      setPolygonPriceTomorrow(parsedData[0].polygonPriceTomorrow);
      setIsPolygonUp(parsedData[0].isPolygonUp);
      setChainlinkPriceToday(parsedData[0].chainlinkPriceToday);
      setChainlinkPriceTomorrow(parsedData[0].chainlinkPriceTomorrow);
      setIsChainlinkUp(parsedData[0].isChainlinkUp);
      setToncoinPriceToday(parsedData[0].toncoinPriceToday);
      setToncoinPriceTomorrow(parsedData[0].toncoinPriceTomorrow);
      setIsToncoinUp(parsedData[0].isToncoinUp);
      setWrappedBitcoinPriceToday(parsedData[0].wrappedBitcoinPriceToday);
      setWrappedBitcoinPriceTomorrow(parsedData[0].wrappedBitcoinPriceTomorrow);
      setIsWrappedBitcoinUp(parsedData[0].isWrappedBitcoinUp);
      setShibaInuPriceToday(parsedData[0].shibaInuPriceToday);
      setShibaInuPriceTomorrow(parsedData[0].shibaInuPriceTomorrow);
      setIsShibaInuUp(parsedData[0].isShibaInuUp);
      setLitecoinPriceToday(parsedData[0].litecoinPriceToday);
      setLitecoinPriceTomorrow(parsedData[0].litecoinPriceTomorrow);
      setIsLitecoinUp(parsedData[0].isLitecoinUp);
      setDaiPriceToday(parsedData[0].daiPriceToday);
      setDaiPriceTomorrow(parsedData[0].daiPriceTomorrow);
      setIsDaiUp(parsedData[0].isDaiUp);
      setBitcoinCashPriceToday(parsedData[0].bitcoinCashPriceToday);
      setBitcoinCashPriceTomorrow(parsedData[0].bitcoinCashPriceTomorrow);
      setIsBitcoinCashUp(parsedData[0].isBitcoinCashUp);
      setCosmosPriceToday(parsedData[0].cosmosPriceToday);
      setCosmosPriceTomorrow(parsedData[0].cosmosPriceTomorrow);
      setIsCosmosUp(parsedData[0].isCosmosUp);
      setUniswapPriceToday(parsedData[0].uniswapPriceToday);
      setUniswapPriceTomorrow(parsedData[0].uniswapPriceTomorrow);
      setIsUniswapUp(parsedData[0].isUniswapUp);
      setNearProtocolPriceToday(parsedData[0].nearProtocolPriceToday);
      setNearProtocolPriceTomorrow(parsedData[0].nearProtocolPriceTomorrow);
      setIsNearProtocolUp(parsedData[0].isNearProtocolUp);
      setInternetProtocolPriceToday(parsedData[0].internetProtocolPriceToday);
      setInternetProtocolPriceTomorrow(
        parsedData[0].internetProtocolPriceTomorrow
      );
      setIsInternetProtocolUp(parsedData[0].isInternetProtocolUp);
      setUnusSedLeoPriceToday(parsedData[0].unusSedLeoPriceToday);
      setUnusSedLeoPriceTomorrow(parsedData[0].unusSedLeoPriceTomorrow);
      setIsUnusSedLeoUp(parsedData[0].isUnusSedLeoUp);
      setStellerPriceToday(parsedData[0].stellerPriceToday);
      setStellerPriceTomorrow(parsedData[0].stellerPriceTomorrow);
      setIsStellerUp(parsedData[0].isStellerUp);
      setOkbPriceToday(parsedData[0].okbPriceToday);
      setOkbPriceTomorrow(parsedData[0].okbPriceTomorrow);
      setIsOkbUp(parsedData[0].isOkbUp);
      setInjectivePriceToday(parsedData[0].injectivePriceToday);
      setInjectivePriceTomorrow(parsedData[0].injectivePriceTomorrow);
      setIsInjectiveUp(parsedData[0].isInjectiveUp);
      setMoneroPriceToday(parsedData[0].moneroPriceToday);
      setMoneroPriceTomorrow(parsedData[0].moneroPriceTomorrow);
      setIsMoneroUp(parsedData[0].isMoneroUp);
      setAptosPriceToday(parsedData[0].aptosPriceToday);
      setAptosPriceTomorrow(parsedData[0].aptosPriceTomorrow);
      setIsAptosUp(parsedData[0].isAptosUp);
      setEthereumClassicPriceToday(parsedData[0].ethereumClassicPriceToday);
      setEthereumClassicPriceTomorrow(
        parsedData[0].ethereumClassicPriceTomorrow
      );
      setIsEthereumClassicUp(parsedData[0].isEthereumClassicUp);
      setHederaPriceToday(parsedData[0].hederaPriceToday);
      setHederaPriceTomorrow(parsedData[0].hederaPriceTomorrow);
      setIsHederaUp(parsedData[0].isHederaUp);
      setImmutableXPriceToday(parsedData[0].immutableXPriceToday);
      setImmutableXPriceTomorrow(parsedData[0].immutableXPriceTomorrow);
      setIsImmutableXUp(parsedData[0].isImmutableXUp);
      setOptimismPriceToday(parsedData[0].optimismPriceToday);
      setOptimismPriceTomorrow(parsedData[0].optimismPriceTomorrow);
      setIsOptimismUp(parsedData[0].isOptimismUp);
      setFilecoinPriceToday(parsedData[0].filecoinPriceToday);
      setFilecoinPriceTomorrow(parsedData[0].filecoinPriceTomorrow);
      setIsFilecoinUp(parsedData[0].isFilecoinUp);
      setVeChainPriceToday(parsedData[0].veChainPriceToday);
      setVeChainPriceTomorrow(parsedData[0].veChainPriceTomorrow);
      setIsVeChainUp(parsedData[0].isVeChainUp);
      setCronosPriceToday(parsedData[0].cronosPriceToday);
      setCronosPriceTomorrow(parsedData[0].cronosPriceTomorrow);
      setIsCronosUp(parsedData[0].isCronosUp);
      setTrueUsdPriceToday(parsedData[0].trueUsdPriceToday);
      setTrueUsdPriceTomorrow(parsedData[0].trueUsdPriceTomorrow);
      setIsTrueUsdUp(parsedData[0].isTrueUsdUp);
      setKaspaPriceToday(parsedData[0].kaspaPriceToday);
      setKaspaPriceTomorrow(parsedData[0].kaspaPriceTomorrow);
      setIsKaspaUp(parsedData[0].isKaspaUp);
      setLidoDaoTokenPriceToday(parsedData[0].lidoDaoTokenPriceToday);
      setLidoDaoTokenPriceTomorrow(parsedData[0].lidoDaoTokenPriceTomorrow);
      setIsLidoDaoTokenUp(parsedData[0].isLidoDaoTokenUp);
      setStacksPriceToday(parsedData[0].stacksPriceToday);
      setStacksPriceTomorrow(parsedData[0].stacksPriceTomorrow);
      setIsStacksUp(parsedData[0].isStacksUp);
      setCelestiaPriceToday(parsedData[0].celestiaPriceToday);
      setCelestiaPriceTomorrow(parsedData[0].celestiaPriceTomorrow);
      setIsCelestiaUp(parsedData[0].isCelestiaUp);
      setAlgorandPriceToday(parsedData[0].algorandPriceToday);
      setAlgorandPriceTomorrow(parsedData[0].algorandPriceTomorrow);
      setIsAlgorandUp(parsedData[0].isAlgorandUp);
      setMantlePriceToday(parsedData[0].mantlePriceToday);
      setMantlePriceTomorrow(parsedData[0].mantlePriceTomorrow);
      setIsMantleUp(parsedData[0].isMantleUp);
      setThorChainPriceToday(parsedData[0].thorChainPriceToday);
      setThorChainPriceTomorrow(parsedData[0].thorChainPriceTomorrow);
      setIsThorChainUp(parsedData[0].isThorChainUp);
      setMultiversXPriceToday(parsedData[0].multiversXPriceToday);
      setMultiversXPriceTomorrow(parsedData[0].multiversXPriceTomorrow);
      setIsMultiversXUp(parsedData[0].isMultiversXUp);
      setRenderTokenPriceToday(parsedData[0].renderTokenPriceToday);
      setRenderTokenPriceTomorrow(parsedData[0].renderTokenPriceTomorrow);
      setIsRenderTokenUp(parsedData[0].isRenderTokenUp);
      setTheGraphPriceToday(parsedData[0].theGraphPriceToday);
      setTheGraphPriceTomorrow(parsedData[0].theGraphPriceTomorrow);
      setIsTheGraphUp(parsedData[0].isTheGraphUp);
      setFirstDigitalUsdPriceToday(parsedData[0].firstDigitalUsdPriceToday);
      setFirstDigitalUsdPriceTomorrow(
        parsedData[0].firstDigitalUsdPriceTomorrow
      );
      setIsFirstDigitalUsdUp(parsedData[0].isFirstDigitalUsdUp);
      setQuantPriceToday(parsedData[0].quantPriceToday);
      setQuantPriceTomorrow(parsedData[0].quantPriceTomorrow);
      setIsQuantUp(parsedData[0].isQuantUp);
    } catch (e) {
      console.log(e);
    }
  };

  const get15Blogs = async () => {
    const url = "https://cryptopredicts.live:1000/api/v1/news/getNews";
    try {
      const data = await fetch(url);
      const parsedData = await data.json();
      setBlogs(parsedData);
      setLoaded(false);
    } catch (e) {
      console.log(e);
    }
  };

  const formatDate = (date) => {
    const options = {
      day: "numeric",
      month: "long",
      year: "numeric",
    };

    const formattedDate = new Date(date).toLocaleDateString("en-GB", options);
    return formattedDate;
  };

  const tomorrowDate = (date) => {
    // Convert the input date to a JavaScript Date object
    const originalDate = new Date(date);

    // Add one day to the original date
    const nextDay = new Date(originalDate);
    nextDay.setDate(originalDate.getDate() + 1);

    // Define formatting options
    const options = {
      day: "numeric",
      month: "long",
      year: "numeric",
    };

    // Format the next day's date
    const formattedDate = nextDay.toLocaleDateString("en-GB", options);
    return formattedDate;
  };

  useEffect(() => {
    if (!hasScrolled) {
      // Scroll to the top of the page when the component mounts
      window.scrollTo(0, 0);
      setHasScrolled(true);
    }
    getPrices();
    get15Blogs();
  }, [hasScrolled]);
  return (
    <>
      <div className="container h1-special" style={{ marginTop: "20px" }}>
        <h1
          className="h1-special"
          style={{ fontFamily: "Poppins", fontWeight: "bold" }}
        >
          Crypto Price Predictions, Crypto News and Prices: Bitcoin Price Prediction Tomorrow, Ethereum Price Prediction Tomorrow,
          Dogecoin Price Prediction Tomorrow and Much More
        </h1>
      </div>
      <h1
        className="text-center h1-title"
        style={{ fontFamily: "Quicksand", marginTop: "25px", color: "green" }}
      >
        Crypto Prices for Tomorrow - GMT<br></br> ({PriceDate})
      </h1>

      <div className="table-responsive">
        <table className="table mt-4">
          <thead>
            <tr>
              <th scope="col">Crypto Coin Name</th>
              <th scope="col">Today Price</th>
              <th scope="col">Tomorrow Price</th>
              <th scope="col">Tomorrow Prediction</th>
              {/* <th scope="col">Coin Details</th> */}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="fw-bold">
                <h1 style={{ fontSize: "17px" }}>
                  1.{" "}
                  <img
                    src="https://static.crypto.com/token/icons/bitcoin/color_icon.png"
                    width={"20px"}
                    alt="icon"
                  ></img>{" "}
                  Bitcoin Price Tomorrow (BTC)
                </h1>
              </td>
              <td>
                {bitcoinPriceToday === "Loading..."
                  ? bitcoinPriceToday
                  : `$${bitcoinPriceToday}`}
              </td>
              <td
                style={{
                  color:
                    bitcoinPriceTomorrow !== "Loading..."
                      ? isBitcoinUp
                        ? "green"
                        : "red"
                      : "black",
                }}
              >
                {bitcoinPriceTomorrow === "Loading..."
                  ? bitcoinPriceTomorrow
                  : `$${bitcoinPriceTomorrow}`}{" "}
                {bitcoinPriceTomorrow !== "Loading..." && (
                  <>
                    {isBitcoinUp ? (
                      <FontAwesomeIcon
                        icon={faArrowUp}
                        style={{ color: "green" }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        style={{ color: "red" }}
                      />
                    )}
                  </>
                )}
              </td>{" "}
              <td
                style={{
                  color:
                    bitcoinPriceTomorrow !== "Loading..."
                      ? isBitcoinUp
                        ? "green"
                        : "red"
                      : "black",
                }}
              >
                {" "}
                {bitcoinPriceTomorrow === "Loading..." ? (
                  "Loading..."
                ) : (
                  <>{isBitcoinUp ? "Going Up" : "Going Down"} </>
                )}
                {bitcoinPriceTomorrow !== "Loading..." && (
                  <>
                    {isBitcoinUp ? (
                      <FontAwesomeIcon
                        icon={faArrowUp}
                        style={{ color: "green" }}
                      ></FontAwesomeIcon>
                    ) : (
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        style={{ color: "red" }}
                      ></FontAwesomeIcon>
                    )}
                  </>
                )}
              </td>
              {/* <td>
                <Link
                  rel="noreferrer"
                  to="/news"
                  className="btn btn-md btn-success"
                >
                  Read Article
                </Link>{" "}
              </td> */}
              {/* <td><a href="#" className="text-primary">View Details</a></td> */}
            </tr>
            <tr>
              <td className="fw-bold">
                <h1 style={{ fontSize: "17px" }}>
                  2.{" "}
                  <img
                    src="https://static.crypto.com/token/icons/ethereum/color_icon.png"
                    width={"20px"}
                    alt="icon"
                  ></img>{" "}
                  Ethereum Price Tomorrow (ETH)
                </h1>
              </td>
              <td>
                {ethereumPriceToday === "Loading..."
                  ? ethereumPriceToday
                  : `$${ethereumPriceToday}`}
              </td>
              <td
                style={{
                  color:
                    ethereumPriceTomorrow !== "Loading..."
                      ? isEthereumUp
                        ? "green"
                        : "red"
                      : "black",
                }}
              >
                {ethereumPriceTomorrow === "Loading..."
                  ? ethereumPriceTomorrow
                  : `$${ethereumPriceTomorrow}`}{" "}
                {ethereumPriceTomorrow !== "Loading..." && (
                  <>
                    {isEthereumUp ? (
                      <FontAwesomeIcon
                        icon={faArrowUp}
                        style={{ color: "green" }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        style={{ color: "red" }}
                      />
                    )}
                  </>
                )}
              </td>{" "}
              <td
                style={{
                  color:
                    ethereumPriceTomorrow !== "Loading..."
                      ? isEthereumUp
                        ? "green"
                        : "red"
                      : "black",
                }}
              >
                {" "}
                {ethereumPriceTomorrow === "Loading..." ? (
                  "Loading..."
                ) : (
                  <>{isEthereumUp ? "Going Up" : "Going Down"} </>
                )}
                {ethereumPriceTomorrow !== "Loading..." && (
                  <>
                    {isEthereumUp ? (
                      <FontAwesomeIcon
                        icon={faArrowUp}
                        style={{ color: "green" }}
                      ></FontAwesomeIcon>
                    ) : (
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        style={{ color: "red" }}
                      ></FontAwesomeIcon>
                    )}
                  </>
                )}
              </td>
              {/* <td><a href="#" className="text-primary">View Details</a></td> */}
            </tr>{" "}
            <tr>
              <td className="fw-bold">
                <h1 style={{ fontSize: "17px" }}>
                  3.{" "}
                  <img
                    src="https://static.crypto.com/token/icons/tether/color_icon.png"
                    width={"20px"}
                    alt="icon"
                  ></img>{" "}
                  Tether Price Tomorrow (USDT)
                </h1>
              </td>
              <td>
                {thetherPriceToday === "Loading..."
                  ? thetherPriceToday
                  : `$${thetherPriceToday}`}{" "}
              </td>
              <td
                style={{
                  color:
                    thetherPriceTomorrow !== "Loading..."
                      ? isThetherUp
                        ? "green"
                        : "red"
                      : "black",
                }}
              >
                {thetherPriceTomorrow === "Loading..."
                  ? thetherPriceTomorrow
                  : `$${thetherPriceTomorrow}`}{" "}
                {thetherPriceTomorrow !== "Loading..." && (
                  <>
                    {isThetherUp ? (
                      <FontAwesomeIcon
                        icon={faArrowUp}
                        style={{ color: "green" }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        style={{ color: "red" }}
                      />
                    )}
                  </>
                )}
              </td>{" "}
              <td
                style={{
                  color:
                    thetherPriceTomorrow !== "Loading..."
                      ? isThetherUp
                        ? "green"
                        : "red"
                      : "black",
                }}
              >
                {" "}
                {thetherPriceTomorrow === "Loading..." ? (
                  "Loading..."
                ) : (
                  <>{isThetherUp ? "Going Up" : "Going Down"} </>
                )}
                {thetherPriceTomorrow !== "Loading..." && (
                  <>
                    {isThetherUp ? (
                      <FontAwesomeIcon
                        icon={faArrowUp}
                        style={{ color: "green" }}
                      ></FontAwesomeIcon>
                    ) : (
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        style={{ color: "red" }}
                      ></FontAwesomeIcon>
                    )}
                  </>
                )}
              </td>
            </tr>
            <tr>
              <td className="fw-bold">
                <h1 style={{ fontSize: "17px" }}>
                  4.{" "}
                  <img
                    src="https://static.crypto.com/token/icons/solana/color_icon.png"
                    width={"20px"}
                    alt="icon"
                  ></img>{" "}
                  Solana Price Tomorrow (SOL)
                </h1>
              </td>
              <td>
                {solanaPriceToday === "Loading..."
                  ? solanaPriceToday
                  : `$${solanaPriceToday}`}{" "}
              </td>
              <td
                style={{
                  color:
                    solanaPriceTomorrow !== "Loading..."
                      ? isSolanaUp
                        ? "green"
                        : "red"
                      : "black",
                }}
              >
                {solanaPriceTomorrow === "Loading..."
                  ? solanaPriceTomorrow
                  : `$${solanaPriceTomorrow}`}{" "}
                {solanaPriceTomorrow !== "Loading..." && (
                  <>
                    {isSolanaUp ? (
                      <FontAwesomeIcon
                        icon={faArrowUp}
                        style={{ color: "green" }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        style={{ color: "red" }}
                      />
                    )}
                  </>
                )}
              </td>{" "}
              <td
                style={{
                  color:
                    solanaPriceTomorrow !== "Loading..."
                      ? isSolanaUp
                        ? "green"
                        : "red"
                      : "black",
                }}
              >
                {" "}
                {solanaPriceTomorrow === "Loading..." ? (
                  "Loading..."
                ) : (
                  <>{isSolanaUp ? "Going Up" : "Going Down"} </>
                )}
                {solanaPriceTomorrow !== "Loading..." && (
                  <>
                    {isSolanaUp ? (
                      <FontAwesomeIcon
                        icon={faArrowUp}
                        style={{ color: "green" }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        style={{ color: "red" }}
                      />
                    )}
                  </>
                )}
              </td>
            </tr>
            <tr>
              <td className="fw-bold">
                <h1 style={{ fontSize: "17px" }}>
                  5.{" "}
                  <img
                    src="https://static.crypto.com/token/icons/bnb/color_icon.png"
                    width={"20px"}
                    alt="icon"
                  ></img>{" "}
                  BNB Price Tomorrow (BNB)
                </h1>
              </td>
              <td>
                {bnbPriceToday === "Loading..."
                  ? bnbPriceToday
                  : `$${bnbPriceToday}`}{" "}
              </td>
              <td
                style={{
                  color:
                    bnbPriceTomorrow !== "Loading..."
                      ? isBnbUp
                        ? "green"
                        : "red"
                      : "black",
                }}
              >
                {bnbPriceTomorrow === "Loading..."
                  ? bnbPriceTomorrow
                  : `$${bnbPriceTomorrow}`}{" "}
                {bnbPriceTomorrow !== "Loading..." && (
                  <>
                    {isBnbUp ? (
                      <FontAwesomeIcon
                        icon={faArrowUp}
                        style={{ color: "green" }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        style={{ color: "red" }}
                      />
                    )}
                  </>
                )}
              </td>{" "}
              <td
                style={{
                  color:
                    bnbPriceTomorrow !== "Loading..."
                      ? isBnbUp
                        ? "green"
                        : "red"
                      : "black",
                }}
              >
                {" "}
                {bnbPriceTomorrow === "Loading..." ? (
                  "Loading..."
                ) : (
                  <>{isBnbUp ? "Going Up" : "Going Down"} </>
                )}
                {bnbPriceTomorrow !== "Loading..." && (
                  <>
                    {isBnbUp ? (
                      <FontAwesomeIcon
                        icon={faArrowUp}
                        style={{ color: "green" }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        style={{ color: "red" }}
                      />
                    )}
                  </>
                )}
              </td>
            </tr>
            <tr>
              <td className="fw-bold">
                <h1 style={{ fontSize: "17px" }}>
                  6.{" "}
                  <img
                    src="https://static.crypto.com/token/icons/xrp/color_icon.png"
                    width={"20px"}
                    alt="icon"
                  ></img>{" "}
                  XRP Price Tomorrow (XRP)
                </h1>
              </td>
              <td>
                {xrpPriceToday === "Loading..."
                  ? xrpPriceToday
                  : `$${xrpPriceToday}`}{" "}
              </td>
              <td
                style={{
                  color:
                    xrpPriceTomorrow !== "Loading..."
                      ? isXrpUp
                        ? "green"
                        : "red"
                      : "black",
                }}
              >
                {xrpPriceTomorrow === "Loading..."
                  ? xrpPriceTomorrow
                  : `$${xrpPriceTomorrow}`}{" "}
                {xrpPriceTomorrow !== "Loading..." && (
                  <>
                    {isXrpUp ? (
                      <FontAwesomeIcon
                        icon={faArrowUp}
                        style={{ color: "green" }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        style={{ color: "red" }}
                      />
                    )}
                  </>
                )}
              </td>{" "}
              <td
                style={{
                  color:
                    xrpPriceTomorrow !== "Loading..."
                      ? isXrpUp
                        ? "green"
                        : "red"
                      : "black",
                }}
              >
                {" "}
                {xrpPriceTomorrow === "Loading..." ? (
                  "Loading..."
                ) : (
                  <>{isXrpUp ? "Going Up" : "Going Down"} </>
                )}
                {xrpPriceTomorrow !== "Loading..." && (
                  <>
                    {isXrpUp ? (
                      <FontAwesomeIcon
                        icon={faArrowUp}
                        style={{ color: "green" }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        style={{ color: "red" }}
                      />
                    )}
                  </>
                )}
              </td>
            </tr>
            <tr>
              <td className="fw-bold">
                <h1 style={{ fontSize: "17px" }}>
                  7.{" "}
                  <img
                    src="https://static.crypto.com/token/icons/usd-coin/color_icon.png"
                    width={"20px"}
                    alt="icon"
                  ></img>{" "}
                  USD Coin Price Tomorrow (USDC)
                </h1>
              </td>
              <td>
                {usdCoinPriceToday === "Loading..."
                  ? usdCoinPriceToday
                  : `$${usdCoinPriceToday}`}{" "}
              </td>
              <td
                style={{
                  color:
                    usdCoinPriceTomorrow !== "Loading..."
                      ? isUsdCoinUp
                        ? "green"
                        : "red"
                      : "black",
                }}
              >
                {usdCoinPriceTomorrow === "Loading..."
                  ? usdCoinPriceTomorrow
                  : `$${usdCoinPriceTomorrow}`}{" "}
                {usdCoinPriceTomorrow !== "Loading..." && (
                  <>
                    {isUsdCoinUp ? (
                      <FontAwesomeIcon
                        icon={faArrowUp}
                        style={{ color: "green" }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        style={{ color: "red" }}
                      />
                    )}
                  </>
                )}
              </td>{" "}
              <td
                style={{
                  color:
                    usdCoinPriceTomorrow !== "Loading..."
                      ? isUsdCoinUp
                        ? "green"
                        : "red"
                      : "black",
                }}
              >
                {" "}
                {usdCoinPriceTomorrow === "Loading..." ? (
                  "Loading..."
                ) : (
                  <>{isUsdCoinUp ? "Going Up" : "Going Down"} </>
                )}
                {usdCoinPriceTomorrow !== "Loading..." && (
                  <>
                    {isUsdCoinUp ? (
                      <FontAwesomeIcon
                        icon={faArrowUp}
                        style={{ color: "green" }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        style={{ color: "red" }}
                      />
                    )}
                  </>
                )}
              </td>
            </tr>
            <tr>
              <td className="fw-bold">
                <h1 style={{ fontSize: "17px" }}>
                  8.{" "}
                  <img
                    src="https://static.crypto.com/token/icons/cardano/color_icon.png"
                    width={"20px"}
                    alt="icon"
                  ></img>{" "}
                  Cardano Price Tomorrow (ADA)
                </h1>
              </td>
              <td>
                {cardonaPriceToday === "Loading..."
                  ? cardonaPriceToday
                  : `$${cardonaPriceToday}`}{" "}
              </td>
              <td
                style={{
                  color:
                    cardonaPriceTomorrow !== "Loading..."
                      ? isCardonaUp
                        ? "green"
                        : "red"
                      : "black",
                }}
              >
                {cardonaPriceTomorrow === "Loading..."
                  ? cardonaPriceTomorrow
                  : `$${cardonaPriceTomorrow}`}{" "}
                {cardonaPriceTomorrow !== "Loading..." && (
                  <>
                    {isCardonaUp ? (
                      <FontAwesomeIcon
                        icon={faArrowUp}
                        style={{ color: "green" }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        style={{ color: "red" }}
                      />
                    )}
                  </>
                )}
              </td>{" "}
              <td
                style={{
                  color:
                    cardonaPriceTomorrow !== "Loading..."
                      ? isCardonaUp
                        ? "green"
                        : "red"
                      : "black",
                }}
              >
                {" "}
                {cardonaPriceTomorrow === "Loading..." ? (
                  "Loading..."
                ) : (
                  <>{isCardonaUp ? "Going Up" : "Going Down"} </>
                )}
                {cardonaPriceTomorrow !== "Loading..." && (
                  <>
                    {isCardonaUp ? (
                      <FontAwesomeIcon
                        icon={faArrowUp}
                        style={{ color: "green" }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        style={{ color: "red" }}
                      />
                    )}
                  </>
                )}
              </td>
            </tr>
            <tr>
              <td className="fw-bold">
                <h1 style={{ fontSize: "17px" }}>
                  9.{" "}
                  <img
                    src="https://static.crypto.com/token/icons/avalanche/color_icon.png"
                    width={"20px"}
                    alt="icon"
                  ></img>{" "}
                  Avalanche Price Tomorrow (AVAX)
                </h1>
              </td>
              <td>
                {avalanchePriceToday === "Loading..."
                  ? avalanchePriceToday
                  : `$${avalanchePriceToday}`}{" "}
              </td>
              <td
                style={{
                  color:
                    avalanchePriceTomorrow !== "Loading..."
                      ? isAvalancheUp
                        ? "green"
                        : "red"
                      : "black",
                }}
              >
                {avalanchePriceTomorrow === "Loading..."
                  ? avalanchePriceTomorrow
                  : `$${avalanchePriceTomorrow}`}{" "}
                {avalanchePriceTomorrow !== "Loading..." && (
                  <>
                    {isAvalancheUp ? (
                      <FontAwesomeIcon
                        icon={faArrowUp}
                        style={{ color: "green" }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        style={{ color: "red" }}
                      />
                    )}
                  </>
                )}
              </td>{" "}
              <td
                style={{
                  color:
                    avalanchePriceTomorrow !== "Loading..."
                      ? isAvalancheUp
                        ? "green"
                        : "red"
                      : "black",
                }}
              >
                {" "}
                {avalanchePriceTomorrow === "Loading..." ? (
                  "Loading..."
                ) : (
                  <>{isAvalancheUp ? "Going Up" : "Going Down"} </>
                )}
                {avalanchePriceTomorrow !== "Loading..." && (
                  <>
                    {isAvalancheUp ? (
                      <FontAwesomeIcon
                        icon={faArrowUp}
                        style={{ color: "green" }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        style={{ color: "red" }}
                      />
                    )}
                  </>
                )}
              </td>
            </tr>
            <tr>
              <td className="fw-bold">
                <h1 style={{ fontSize: "17px" }}>
                  10.{" "}
                  <img
                    src="https://static.crypto.com/token/icons/dogecoin/color_icon.png"
                    width={"20px"}
                    alt="icon"
                  ></img>{" "}
                  Dogecoin Price Tomorrow (DOGE)
                </h1>
              </td>
              <td>
                {dogeCoinPriceToday === "Loading..."
                  ? dogeCoinPriceToday
                  : `$${dogeCoinPriceToday}`}{" "}
              </td>
              <td
                style={{
                  color:
                    dogeCoinPriceTomorrow !== "Loading..."
                      ? isDogeCoinUp
                        ? "green"
                        : "red"
                      : "black",
                }}
              >
                {dogeCoinPriceTomorrow === "Loading..."
                  ? dogeCoinPriceTomorrow
                  : `$${dogeCoinPriceTomorrow}`}{" "}
                {dogeCoinPriceTomorrow !== "Loading..." && (
                  <>
                    {isDogeCoinUp ? (
                      <FontAwesomeIcon
                        icon={faArrowUp}
                        style={{ color: "green" }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        style={{ color: "red" }}
                      />
                    )}
                  </>
                )}
              </td>{" "}
              <td
                style={{
                  color:
                    dogeCoinPriceTomorrow !== "Loading..."
                      ? isDogeCoinUp
                        ? "green"
                        : "red"
                      : "black",
                }}
              >
                {" "}
                {dogeCoinPriceTomorrow === "Loading..." ? (
                  "Loading..."
                ) : (
                  <>{isDogeCoinUp ? "Going Up" : "Going Down"} </>
                )}
                {dogeCoinPriceTomorrow !== "Loading..." && (
                  <>
                    {isDogeCoinUp ? (
                      <FontAwesomeIcon
                        icon={faArrowUp}
                        style={{ color: "green" }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        style={{ color: "red" }}
                      />
                    )}
                  </>
                )}
              </td>
            </tr>
            <tr>
              <td className="fw-bold">
                <h1 style={{ fontSize: "17px" }}>
                  11.{" "}
                  <img
                    src="https://static.crypto.com/token/icons/polkadot-new/color_icon.png"
                    width={"20px"}
                    alt="icon"
                  ></img>{" "}
                  Polkadot Price Tomorrow (DOT)
                </h1>
              </td>
              <td>
                {polkadotPriceToday === "Loading..."
                  ? polkadotPriceToday
                  : `$${polkadotPriceToday}`}{" "}
              </td>
              <td
                style={{
                  color:
                    polkadotPriceTomorrow !== "Loading..."
                      ? isPolkadotUp
                        ? "green"
                        : "red"
                      : "black",
                }}
              >
                {polkadotPriceTomorrow === "Loading..."
                  ? polkadotPriceTomorrow
                  : `$${polkadotPriceTomorrow}`}{" "}
                {polkadotPriceTomorrow !== "Loading..." && (
                  <>
                    {isPolkadotUp ? (
                      <FontAwesomeIcon
                        icon={faArrowUp}
                        style={{ color: "green" }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        style={{ color: "red" }}
                      />
                    )}
                  </>
                )}
              </td>{" "}
              <td
                style={{
                  color:
                    polkadotPriceTomorrow !== "Loading..."
                      ? isPolkadotUp
                        ? "green"
                        : "red"
                      : "black",
                }}
              >
                {" "}
                {polkadotPriceTomorrow === "Loading..." ? (
                  "Loading..."
                ) : (
                  <>{isPolkadotUp ? "Going Up" : "Going Down"} </>
                )}
                {polkadotPriceTomorrow !== "Loading..." && (
                  <>
                    {isPolkadotUp ? (
                      <FontAwesomeIcon
                        icon={faArrowUp}
                        style={{ color: "green" }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        style={{ color: "red" }}
                      />
                    )}
                  </>
                )}
              </td>
            </tr>
            <tr>
              <td className="fw-bold">
                <h1 style={{ fontSize: "17px" }}>
                  12.{" "}
                  <img
                    src="https://static.crypto.com/token/icons/tron/color_icon.png"
                    width={"20px"}
                    alt="icon"
                  ></img>{" "}
                  TRON Price Tomorrow (TRX)
                </h1>
              </td>
              <td>
                {tronPriceToday === "Loading..."
                  ? tronPriceToday
                  : `$${tronPriceToday}`}{" "}
              </td>
              <td
                style={{
                  color:
                    tronPriceTomorrow !== "Loading..."
                      ? isTronUp
                        ? "green"
                        : "red"
                      : "black",
                }}
              >
                {tronPriceTomorrow === "Loading..."
                  ? tronPriceTomorrow
                  : `$${tronPriceTomorrow}`}{" "}
                {tronPriceTomorrow !== "Loading..." && (
                  <>
                    {isTronUp ? (
                      <FontAwesomeIcon
                        icon={faArrowUp}
                        style={{ color: "green" }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        style={{ color: "red" }}
                      />
                    )}
                  </>
                )}
              </td>{" "}
              <td
                style={{
                  color:
                    tronPriceTomorrow !== "Loading..."
                      ? isTronUp
                        ? "green"
                        : "red"
                      : "black",
                }}
              >
                {" "}
                {tronPriceTomorrow === "Loading..." ? (
                  "Loading..."
                ) : (
                  <>{isTronUp ? "Going Up" : "Going Down"} </>
                )}
                {tronPriceTomorrow !== "Loading..." && (
                  <>
                    {isTronUp ? (
                      <FontAwesomeIcon
                        icon={faArrowUp}
                        style={{ color: "green" }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        style={{ color: "red" }}
                      />
                    )}
                  </>
                )}
              </td>
            </tr>
            <tr>
              <td className="fw-bold">
                <h1 style={{ fontSize: "17px" }}>
                  13.{" "}
                  <img
                    src="https://static.crypto.com/token/icons/polygon/color_icon.png"
                    width={"20px"}
                    alt="icon"
                  ></img>{" "}
                  Polygon Price Tomorrow (MATIC)
                </h1>
              </td>
              <td>
                {polygonPriceToday === "Loading..."
                  ? polygonPriceToday
                  : `$${polygonPriceToday}`}{" "}
              </td>
              <td
                style={{
                  color:
                    polygonPriceTomorrow !== "Loading..."
                      ? isPolygonUp
                        ? "green"
                        : "red"
                      : "black",
                }}
              >
                {polygonPriceTomorrow === "Loading..."
                  ? polygonPriceTomorrow
                  : `$${polygonPriceTomorrow}`}{" "}
                {polygonPriceTomorrow !== "Loading..." && (
                  <>
                    {isPolygonUp ? (
                      <FontAwesomeIcon
                        icon={faArrowUp}
                        style={{ color: "green" }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        style={{ color: "red" }}
                      />
                    )}
                  </>
                )}
              </td>{" "}
              <td
                style={{
                  color:
                    polygonPriceTomorrow !== "Loading..."
                      ? isPolygonUp
                        ? "green"
                        : "red"
                      : "black",
                }}
              >
                {" "}
                {polygonPriceTomorrow === "Loading..." ? (
                  "Loading..."
                ) : (
                  <>{isPolygonUp ? "Going Up" : "Going Down"} </>
                )}
                {polygonPriceTomorrow !== "Loading..." && (
                  <>
                    {isPolygonUp ? (
                      <FontAwesomeIcon
                        icon={faArrowUp}
                        style={{ color: "green" }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        style={{ color: "red" }}
                      />
                    )}
                  </>
                )}
              </td>
              
            </tr>
            <tr>
              <td className="fw-bold">
                <h1 style={{ fontSize: "17px" }}>
                  14.{" "}
                  <img
                    src="https://static.crypto.com/token/icons/chainlink/color_icon.png"
                    width={"20px"}
                    alt="icon"
                  ></img>{" "}
                  Chainlink Price Tomorrow (LINK)
                </h1>
              </td>
              <td>
                {chainlinkPriceToday === "Loading..."
                  ? chainlinkPriceToday
                  : `$${chainlinkPriceToday}`}{" "}
              </td>
              <td
                style={{
                  color:
                    chainlinkPriceTomorrow !== "Loading..."
                      ? isChainlinkUp
                        ? "green"
                        : "red"
                      : "black",
                }}
              >
                {chainlinkPriceTomorrow === "Loading..."
                  ? chainlinkPriceTomorrow
                  : `$${chainlinkPriceTomorrow}`}{" "}
                {chainlinkPriceTomorrow !== "Loading..." && (
                  <>
                    {isChainlinkUp ? (
                      <FontAwesomeIcon
                        icon={faArrowUp}
                        style={{ color: "green" }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        style={{ color: "red" }}
                      />
                    )}
                  </>
                )}
              </td>{" "}
              <td
                style={{
                  color:
                    chainlinkPriceTomorrow !== "Loading..."
                      ? isChainlinkUp
                        ? "green"
                        : "red"
                      : "black",
                }}
              >
                {" "}
                {chainlinkPriceTomorrow === "Loading..." ? (
                  "Loading..."
                ) : (
                  <>{isChainlinkUp ? "Going Up" : "Going Down"} </>
                )}
                {chainlinkPriceTomorrow !== "Loading..." && (
                  <>
                    {isChainlinkUp ? (
                      <FontAwesomeIcon
                        icon={faArrowUp}
                        style={{ color: "green" }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        style={{ color: "red" }}
                      />
                    )}
                  </>
                )}
              </td>
            </tr>
            <tr>
              <td className="fw-bold">
                <h1 style={{ fontSize: "17px" }}>
                  15.{" "}
                  <img
                    src="https://static.crypto.com/token/icons/toncoin/color_icon.png"
                    width={"20px"}
                    alt="icon"
                  ></img>{" "}
                  Toncoin Price Tomorrow (TONCOIN)
                </h1>
              </td>
              <td>
                {toncoinPriceToday === "Loading..."
                  ? toncoinPriceToday
                  : `$${toncoinPriceToday}`}{" "}
              </td>
              <td
                style={{
                  color:
                    toncoinPriceTomorrow !== "Loading..."
                      ? isToncoinUp
                        ? "green"
                        : "red"
                      : "black",
                }}
              >
                {toncoinPriceTomorrow === "Loading..."
                  ? toncoinPriceTomorrow
                  : `$${toncoinPriceTomorrow}`}{" "}
                {toncoinPriceTomorrow !== "Loading..." && (
                  <>
                    {isToncoinUp ? (
                      <FontAwesomeIcon
                        icon={faArrowUp}
                        style={{ color: "green" }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        style={{ color: "red" }}
                      />
                    )}
                  </>
                )}
              </td>{" "}
              <td
                style={{
                  color:
                    toncoinPriceTomorrow !== "Loading..."
                      ? isToncoinUp
                        ? "green"
                        : "red"
                      : "black",
                }}
              >
                {" "}
                {toncoinPriceTomorrow === "Loading..." ? (
                  "Loading..."
                ) : (
                  <>{isToncoinUp ? "Going Up" : "Going Down"} </>
                )}
                {toncoinPriceTomorrow !== "Loading..." && (
                  <>
                    {isToncoinUp ? (
                      <FontAwesomeIcon
                        icon={faArrowUp}
                        style={{ color: "green" }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        style={{ color: "red" }}
                      />
                    )}
                  </>
                )}
              </td>
            </tr>
            {/* <NewsletterSubscription /> */}
            <tr>
              <td className="fw-bold">
                <h1 style={{ fontSize: "17px" }}>
                  16.{" "}
                  <img
                    src="https://static.crypto.com/token/icons/wrapped-bitcoin/color_icon.png"
                    width={"20px"}
                    alt="icon"
                  ></img>{" "}
                  Wrapped Bitcoin Price Tomorrow (WBTC)
                </h1>
              </td>
              <td>
                {wrappedBitcoinPriceToday === "Loading..."
                  ? wrappedBitcoinPriceToday
                  : `$${wrappedBitcoinPriceToday}`}{" "}
              </td>
              <td
                style={{
                  color:
                    wrappedBitcoinPriceTomorrow !== "Loading..."
                      ? isWrappedBitcoinUp
                        ? "green"
                        : "red"
                      : "black",
                }}
              >
                {wrappedBitcoinPriceTomorrow === "Loading..."
                  ? wrappedBitcoinPriceTomorrow
                  : `$${wrappedBitcoinPriceTomorrow}`}{" "}
                {wrappedBitcoinPriceTomorrow !== "Loading..." && (
                  <>
                    {isWrappedBitcoinUp ? (
                      <FontAwesomeIcon
                        icon={faArrowUp}
                        style={{ color: "green" }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        style={{ color: "red" }}
                      />
                    )}
                  </>
                )}
              </td>{" "}
              <td
                style={{
                  color:
                    wrappedBitcoinPriceTomorrow !== "Loading..."
                      ? isWrappedBitcoinUp
                        ? "green"
                        : "red"
                      : "black",
                }}
              >
                {" "}
                {wrappedBitcoinPriceTomorrow === "Loading..." ? (
                  "Loading..."
                ) : (
                  <>{isWrappedBitcoinUp ? "Going Up" : "Going Down"} </>
                )}
                {wrappedBitcoinPriceTomorrow !== "Loading..." && (
                  <>
                    {isWrappedBitcoinUp ? (
                      <FontAwesomeIcon
                        icon={faArrowUp}
                        style={{ color: "green" }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        style={{ color: "red" }}
                      />
                    )}
                  </>
                )}
              </td>
            </tr>
            <tr>
              <td className="fw-bold">
                <h1 style={{ fontSize: "17px" }}>
                  17.{" "}
                  <img
                    src="https://static.crypto.com/token/icons/shiba-inu/color_icon.png"
                    width={"20px"}
                    alt="icon"
                  ></img>{" "}
                  SHIBA INU Price Tomorrow (SHIB)
                </h1>
              </td>
              <td>
                {shibaInuPriceToday === "Loading..."
                  ? shibaInuPriceToday
                  : `$${shibaInuPriceToday}`}{" "}
              </td>
              <td
                style={{
                  color:
                    shibaInuPriceTomorrow !== "Loading..."
                      ? isShibaInuUp
                        ? "green"
                        : "red"
                      : "black",
                }}
              >
                {shibaInuPriceTomorrow === "Loading..."
                  ? shibaInuPriceTomorrow
                  : `$${shibaInuPriceTomorrow}`}{" "}
                {shibaInuPriceTomorrow !== "Loading..." && (
                  <>
                    {isShibaInuUp ? (
                      <FontAwesomeIcon
                        icon={faArrowUp}
                        style={{ color: "green" }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        style={{ color: "red" }}
                      />
                    )}
                  </>
                )}
              </td>{" "}
              <td
                style={{
                  color:
                    shibaInuPriceTomorrow !== "Loading..."
                      ? isShibaInuUp
                        ? "green"
                        : "red"
                      : "black",
                }}
              >
                {" "}
                {shibaInuPriceTomorrow === "Loading..." ? (
                  "Loading..."
                ) : (
                  <>{isShibaInuUp ? "Going Up" : "Going Down"} </>
                )}
                {shibaInuPriceTomorrow !== "Loading..." && (
                  <>
                    {isShibaInuUp ? (
                      <FontAwesomeIcon
                        icon={faArrowUp}
                        style={{ color: "green" }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        style={{ color: "red" }}
                      />
                    )}
                  </>
                )}
              </td>
            </tr>
            <tr>
              <td className="fw-bold">
                <h1 style={{ fontSize: "17px" }}>
                  18.{" "}
                  <img
                    src="https://static.crypto.com/token/icons/litecoin/color_icon.png"
                    width={"20px"}
                    alt="icon"
                  ></img>{" "}
                  Litecoin Price Tomorrow (LTC)
                </h1>
              </td>
              <td>
                {litecoinPriceToday === "Loading..."
                  ? litecoinPriceToday
                  : `$${litecoinPriceToday}`}{" "}
              </td>
              <td
                style={{
                  color:
                    litecoinPriceTomorrow !== "Loading..."
                      ? isLitecoinUp
                        ? "green"
                        : "red"
                      : "black",
                }}
              >
                {litecoinPriceTomorrow === "Loading..."
                  ? litecoinPriceTomorrow
                  : `$${litecoinPriceTomorrow}`}{" "}
                {litecoinPriceTomorrow !== "Loading..." && (
                  <>
                    {isLitecoinUp ? (
                      <FontAwesomeIcon
                        icon={faArrowUp}
                        style={{ color: "green" }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        style={{ color: "red" }}
                      />
                    )}
                  </>
                )}
              </td>{" "}
              <td
                style={{
                  color:
                    litecoinPriceTomorrow !== "Loading..."
                      ? isLitecoinUp
                        ? "green"
                        : "red"
                      : "black",
                }}
              >
                {" "}
                {litecoinPriceTomorrow === "Loading..." ? (
                  "Loading..."
                ) : (
                  <>{isLitecoinUp ? "Going Up" : "Going Down"} </>
                )}
                {litecoinPriceTomorrow !== "Loading..." && (
                  <>
                    {isLitecoinUp ? (
                      <FontAwesomeIcon
                        icon={faArrowUp}
                        style={{ color: "green" }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        style={{ color: "red" }}
                      />
                    )}
                  </>
                )}
              </td>
            </tr>
            <tr>
              <td className="fw-bold">
                <h1 style={{ fontSize: "17px" }}>
                  19.{" "}
                  <img
                    src="https://static.crypto.com/token/icons/multi-collateral-dai/color_icon.png"
                    width={"20px"}
                    alt="icon"
                  ></img>{" "}
                  Dai Price Tomorrow (DAI)
                </h1>
              </td>
              <td>
                {daiPriceToday === "Loading..."
                  ? daiPriceToday
                  : `$${daiPriceToday}`}{" "}
              </td>
              <td
                style={{
                  color:
                    daiPriceTomorrow !== "Loading..."
                      ? isDaiUp
                        ? "green"
                        : "red"
                      : "black",
                }}
              >
                {daiPriceTomorrow === "Loading..."
                  ? daiPriceTomorrow
                  : `$${daiPriceTomorrow}`}{" "}
                {daiPriceTomorrow !== "Loading..." && (
                  <>
                    {isDaiUp ? (
                      <FontAwesomeIcon
                        icon={faArrowUp}
                        style={{ color: "green" }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        style={{ color: "red" }}
                      />
                    )}
                  </>
                )}
              </td>{" "}
              <td
                style={{
                  color:
                    daiPriceTomorrow !== "Loading..."
                      ? isDaiUp
                        ? "green"
                        : "red"
                      : "black",
                }}
              >
                {" "}
                {daiPriceTomorrow === "Loading..." ? (
                  "Loading..."
                ) : (
                  <>{isDaiUp ? "Going Up" : "Going Down"} </>
                )}
                {daiPriceTomorrow !== "Loading..." && (
                  <>
                    {isDaiUp ? (
                      <FontAwesomeIcon
                        icon={faArrowUp}
                        style={{ color: "green" }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        style={{ color: "red" }}
                      />
                    )}
                  </>
                )}
              </td>
            </tr>
            <tr>
              <td className="fw-bold">
                <h1 style={{ fontSize: "17px" }}>
                  20.{" "}
                  <img
                    src="https://static.crypto.com/token/icons/bitcoin-cash/color_icon.png"
                    width={"20px"}
                    alt="icon"
                  ></img>{" "}
                  Bitcoin Cash Price Tomorrow (BCH)
                </h1>
              </td>
              <td>
                {bitcoinCashPriceToday === "Loading..."
                  ? bitcoinCashPriceToday
                  : `$${bitcoinCashPriceToday}`}{" "}
              </td>
              <td
                style={{
                  color:
                    bitcoinCashPriceTomorrow !== "Loading..."
                      ? isBitcoinCashUp
                        ? "green"
                        : "red"
                      : "black",
                }}
              >
                {bitcoinCashPriceTomorrow === "Loading..."
                  ? bitcoinCashPriceTomorrow
                  : `$${bitcoinCashPriceTomorrow}`}{" "}
                {bitcoinCashPriceTomorrow !== "Loading..." && (
                  <>
                    {isBitcoinCashUp ? (
                      <FontAwesomeIcon
                        icon={faArrowUp}
                        style={{ color: "green" }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        style={{ color: "red" }}
                      />
                    )}
                  </>
                )}
              </td>{" "}
              <td
                style={{
                  color:
                    bitcoinCashPriceTomorrow !== "Loading..."
                      ? isBitcoinCashUp
                        ? "green"
                        : "red"
                      : "black",
                }}
              >
                {" "}
                {bitcoinCashPriceTomorrow === "Loading..." ? (
                  "Loading..."
                ) : (
                  <>{isBitcoinCashUp ? "Going Up" : "Going Down"} </>
                )}
                {bitcoinCashPriceTomorrow !== "Loading..." && (
                  <>
                    {isBitcoinCashUp ? (
                      <FontAwesomeIcon
                        icon={faArrowUp}
                        style={{ color: "green" }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        style={{ color: "red" }}
                      />
                    )}
                  </>
                )}
              </td>
            </tr>
            <tr>
              <td className="fw-bold">
                <h1 style={{ fontSize: "17px" }}>
                  21.{" "}
                  <img
                    src="https://static.crypto.com/token/icons_v2/cosmos/1322-1698380228911.jpg"
                    width={"20px"}
                    alt="icon"
                  ></img>{" "}
                  Cosmos Price Tomorrow (ATOM)
                </h1>
              </td>
              <td>
                {cosmosPriceToday === "Loading..."
                  ? cosmosPriceToday
                  : `$${cosmosPriceToday}`}{" "}
              </td>
              <td
                style={{
                  color:
                    cosmosPriceTomorrow !== "Loading..."
                      ? isCosmosUp
                        ? "green"
                        : "red"
                      : "black",
                }}
              >
                {cosmosPriceTomorrow === "Loading..."
                  ? cosmosPriceTomorrow
                  : `$${cosmosPriceTomorrow}`}{" "}
                {cosmosPriceTomorrow !== "Loading..." && (
                  <>
                    {isCosmosUp ? (
                      <FontAwesomeIcon
                        icon={faArrowUp}
                        style={{ color: "green" }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        style={{ color: "red" }}
                      />
                    )}
                  </>
                )}
              </td>{" "}
              <td
                style={{
                  color:
                    cosmosPriceTomorrow !== "Loading..."
                      ? isCosmosUp
                        ? "green"
                        : "red"
                      : "black",
                }}
              >
                {" "}
                {cosmosPriceTomorrow === "Loading..." ? (
                  "Loading..."
                ) : (
                  <>{isCosmosUp ? "Going Up" : "Going Down"} </>
                )}
                {cosmosPriceTomorrow !== "Loading..." && (
                  <>
                    {isCosmosUp ? (
                      <FontAwesomeIcon
                        icon={faArrowUp}
                        style={{ color: "green" }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        style={{ color: "red" }}
                      />
                    )}
                  </>
                )}
              </td>
            </tr>
            <tr>
              <td className="fw-bold">
                <h1 style={{ fontSize: "17px" }}>
                  22.{" "}
                  <img
                    src="https://static.crypto.com/token/icons/uniswap/color_icon.png"
                    width={"20px"}
                    alt="icon"
                  ></img>{" "}
                  Uniswap Price Tomorrow (UNI)
                </h1>
              </td>
              <td>
                {uniswapPriceToday === "Loading..."
                  ? uniswapPriceToday
                  : `$${uniswapPriceToday}`}{" "}
              </td>
              <td
                style={{
                  color:
                    uniswapPriceTomorrow !== "Loading..."
                      ? isUniswapUp
                        ? "green"
                        : "red"
                      : "black",
                }}
              >
                {uniswapPriceTomorrow === "Loading..."
                  ? uniswapPriceTomorrow
                  : `$${uniswapPriceTomorrow}`}{" "}
                {uniswapPriceTomorrow !== "Loading..." && (
                  <>
                    {isUniswapUp ? (
                      <FontAwesomeIcon
                        icon={faArrowUp}
                        style={{ color: "green" }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        style={{ color: "red" }}
                      />
                    )}
                  </>
                )}
              </td>{" "}
              <td
                style={{
                  color:
                    uniswapPriceTomorrow !== "Loading..."
                      ? isUniswapUp
                        ? "green"
                        : "red"
                      : "black",
                }}
              >
                {" "}
                {uniswapPriceTomorrow === "Loading..." ? (
                  "Loading..."
                ) : (
                  <>{isUniswapUp ? "Going Up" : "Going Down"} </>
                )}
                {uniswapPriceTomorrow !== "Loading..." && (
                  <>
                    {isUniswapUp ? (
                      <FontAwesomeIcon
                        icon={faArrowUp}
                        style={{ color: "green" }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        style={{ color: "red" }}
                      />
                    )}
                  </>
                )}
              </td>
            </tr>
            <tr>
              <td className="fw-bold">
                <h1 style={{ fontSize: "17px" }}>
                  23.{" "}
                  <img
                    src="https://static.crypto.com/token/icons/near-protocol/color_icon.png"
                    width={"20px"}
                    alt="icon"
                  ></img>{" "}
                  NEAR Protocol Price Tomorrow (NEAR)
                </h1>
              </td>
              <td>
                {nearProtocolPriceToday === "Loading..."
                  ? nearProtocolPriceToday
                  : `$${nearProtocolPriceToday}`}{" "}
              </td>
              <td
                style={{
                  color:
                    nearProtocolPriceTomorrow !== "Loading..."
                      ? isNearProtocolUp
                        ? "green"
                        : "red"
                      : "black",
                }}
              >
                {nearProtocolPriceTomorrow === "Loading..."
                  ? nearProtocolPriceTomorrow
                  : `$${nearProtocolPriceTomorrow}`}{" "}
                {nearProtocolPriceTomorrow !== "Loading..." && (
                  <>
                    {isNearProtocolUp ? (
                      <FontAwesomeIcon
                        icon={faArrowUp}
                        style={{ color: "green" }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        style={{ color: "red" }}
                      />
                    )}
                  </>
                )}
              </td>{" "}
              <td
                style={{
                  color:
                    nearProtocolPriceTomorrow !== "Loading..."
                      ? isNearProtocolUp
                        ? "green"
                        : "red"
                      : "black",
                }}
              >
                {" "}
                {nearProtocolPriceTomorrow === "Loading..." ? (
                  "Loading..."
                ) : (
                  <>{isNearProtocolUp ? "Going Up" : "Going Down"} </>
                )}
                {nearProtocolPriceTomorrow !== "Loading..." && (
                  <>
                    {isNearProtocolUp ? (
                      <FontAwesomeIcon
                        icon={faArrowUp}
                        style={{ color: "green" }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        style={{ color: "red" }}
                      />
                    )}
                  </>
                )}
              </td>
            </tr>
            <tr>
              <td className="fw-bold">
                <h1 style={{ fontSize: "17px" }}>
                  24.{" "}
                  <img
                    src="https://static.crypto.com/token/icons/internet-computer/color_icon.png"
                    width={"20px"}
                    alt="icon"
                  ></img>{" "}
                  Internet Protocol Price Tomorrow (ICP)
                </h1>
              </td>
              <td>
                {internetProtocolPriceToday === "Loading..."
                  ? internetProtocolPriceToday
                  : `$${internetProtocolPriceToday}`}{" "}
              </td>
              <td
                style={{
                  color:
                    internetProtocolPriceTomorrow !== "Loading..."
                      ? isInternetProtocolUp
                        ? "green"
                        : "red"
                      : "black",
                }}
              >
                {internetProtocolPriceTomorrow === "Loading..."
                  ? internetProtocolPriceTomorrow
                  : `$${internetProtocolPriceTomorrow}`}{" "}
                {internetProtocolPriceTomorrow !== "Loading..." && (
                  <>
                    {isInternetProtocolUp ? (
                      <FontAwesomeIcon
                        icon={faArrowUp}
                        style={{ color: "green" }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        style={{ color: "red" }}
                      />
                    )}
                  </>
                )}
              </td>{" "}
              <td
                style={{
                  color:
                    internetProtocolPriceTomorrow !== "Loading..."
                      ? isInternetProtocolUp
                        ? "green"
                        : "red"
                      : "black",
                }}
              >
                {" "}
                {internetProtocolPriceTomorrow === "Loading..." ? (
                  "Loading..."
                ) : (
                  <>{isInternetProtocolUp ? "Going Up" : "Going Down"} </>
                )}
                {internetProtocolPriceTomorrow !== "Loading..." && (
                  <>
                    {isInternetProtocolUp ? (
                      <FontAwesomeIcon
                        icon={faArrowUp}
                        style={{ color: "green" }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        style={{ color: "red" }}
                      />
                    )}
                  </>
                )}
              </td>
            </tr>
            <tr>
              <td className="fw-bold">
                <h1 style={{ fontSize: "17px" }}>
                  25.{" "}
                  <img
                    src="https://static.crypto.com/token/icons/unus-sed-leo/color_icon.png"
                    width={"20px"}
                    alt="icon"
                  ></img>{" "}
                  UNUS SED LEO Price Tomorrow (LEO)
                </h1>
              </td>
              <td>
                {unusSedLeoPriceToday === "Loading..."
                  ? unusSedLeoPriceToday
                  : `$${unusSedLeoPriceToday}`}{" "}
              </td>
              <td
                style={{
                  color:
                    unusSedLeoPriceTomorrow !== "Loading..."
                      ? isUnusSedLeoUp
                        ? "green"
                        : "red"
                      : "black",
                }}
              >
                {unusSedLeoPriceTomorrow === "Loading..."
                  ? unusSedLeoPriceTomorrow
                  : `$${unusSedLeoPriceTomorrow}`}{" "}
                {unusSedLeoPriceTomorrow !== "Loading..." && (
                  <>
                    {isUnusSedLeoUp ? (
                      <FontAwesomeIcon
                        icon={faArrowUp}
                        style={{ color: "green" }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        style={{ color: "red" }}
                      />
                    )}
                  </>
                )}
              </td>{" "}
              <td
                style={{
                  color:
                    unusSedLeoPriceTomorrow !== "Loading..."
                      ? isUnusSedLeoUp
                        ? "green"
                        : "red"
                      : "black",
                }}
              >
                {" "}
                {unusSedLeoPriceToday === "Loading..." ? (
                  "Loading..."
                ) : (
                  <>{isUnusSedLeoUp ? "Going Up" : "Going Down"} </>
                )}
                {unusSedLeoPriceTomorrow !== "Loading..." && (
                  <>
                    {isUnusSedLeoUp ? (
                      <FontAwesomeIcon
                        icon={faArrowUp}
                        style={{ color: "green" }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        style={{ color: "red" }}
                      />
                    )}
                  </>
                )}
              </td>
            </tr>
            <tr>
              <td className="fw-bold">
                <h1 style={{ fontSize: "17px" }}>
                  26.{" "}
                  <img
                    src="https://static.crypto.com/token/icons/stellar/color_icon.png"
                    width={"20px"}
                    alt="icon"
                  ></img>{" "}
                  Stellar Price Tomorrow (XLM)
                </h1>
              </td>
              <td>
                {stellerPriceToday === "Loading..."
                  ? stellerPriceToday
                  : `$${stellerPriceToday}`}{" "}
              </td>
              <td
                style={{
                  color:
                    stellerPriceTomorrow !== "Loading..."
                      ? isStellerUp
                        ? "green"
                        : "red"
                      : "black",
                }}
              >
                {stellerPriceTomorrow === "Loading..."
                  ? stellerPriceTomorrow
                  : `$${stellerPriceTomorrow}`}{" "}
                {stellerPriceTomorrow !== "Loading..." && (
                  <>
                    {isStellerUp ? (
                      <FontAwesomeIcon
                        icon={faArrowUp}
                        style={{ color: "green" }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        style={{ color: "red" }}
                      />
                    )}
                  </>
                )}
              </td>{" "}
              <td
                style={{
                  color:
                    stellerPriceTomorrow !== "Loading..."
                      ? isStellerUp
                        ? "green"
                        : "red"
                      : "black",
                }}
              >
                {" "}
                {stellerPriceTomorrow === "Loading..." ? (
                  "Loading..."
                ) : (
                  <>{isStellerUp ? "Going Up" : "Going Down"} </>
                )}
                {stellerPriceTomorrow !== "Loading..." && (
                  <>
                    {isStellerUp ? (
                      <FontAwesomeIcon
                        icon={faArrowUp}
                        style={{ color: "green" }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        style={{ color: "red" }}
                      />
                    )}
                  </>
                )}
              </td>
            </tr>
            <tr>
              <td className="fw-bold">
                <h1 style={{ fontSize: "17px" }}>
                  27.{" "}
                  <img
                    src="https://static.crypto.com/token/icons/okb/color_icon.png"
                    width={"20px"}
                    alt="icon"
                  ></img>{" "}
                  OKB Price Tomorrow (OKB)
                </h1>
              </td>
              <td>
                {okbPriceToday === "Loading..."
                  ? okbPriceToday
                  : `$${okbPriceToday}`}{" "}
              </td>
              <td
                style={{
                  color:
                    okbPriceTomorrow !== "Loading..."
                      ? isOkbUp
                        ? "green"
                        : "red"
                      : "black",
                }}
              >
                {okbPriceTomorrow === "Loading..."
                  ? okbPriceTomorrow
                  : `$${okbPriceTomorrow}`}{" "}
                {okbPriceTomorrow !== "Loading..." && (
                  <>
                    {isOkbUp ? (
                      <FontAwesomeIcon
                        icon={faArrowUp}
                        style={{ color: "green" }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        style={{ color: "red" }}
                      />
                    )}
                  </>
                )}
              </td>{" "}
              <td
                style={{
                  color:
                    okbPriceTomorrow !== "Loading..."
                      ? isOkbUp
                        ? "green"
                        : "red"
                      : "black",
                }}
              >
                {" "}
                {okbPriceTomorrow === "Loading..." ? (
                  "Loading..."
                ) : (
                  <>{isOkbUp ? "Going Up" : "Going Down"} </>
                )}
                {okbPriceTomorrow !== "Loading..." && (
                  <>
                    {isOkbUp ? (
                      <FontAwesomeIcon
                        icon={faArrowUp}
                        style={{ color: "green" }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        style={{ color: "red" }}
                      />
                    )}
                  </>
                )}
              </td>
              
            </tr>
            <tr>
              <td className="fw-bold">
                <h1 style={{ fontSize: "17px" }}>
                  28.{" "}
                  <img
                    src="https://static.crypto.com/token/icons/injective-protocol/color_icon.png"
                    width={"20px"}
                    alt="icon"
                  ></img>{" "}
                  Injective Price Tomorrow (INJ)
                </h1>
              </td>
              <td>
                {injectivePriceToday === "Loading..."
                  ? injectivePriceToday
                  : `$${injectivePriceToday}`}{" "}
              </td>
              <td
                style={{
                  color:
                    injectivePriceTomorrow !== "Loading..."
                      ? isInjectiveUp
                        ? "green"
                        : "red"
                      : "black",
                }}
              >
                {injectivePriceTomorrow === "Loading..."
                  ? injectivePriceTomorrow
                  : `$${injectivePriceTomorrow}`}{" "}
                {injectivePriceTomorrow !== "Loading..." && (
                  <>
                    {isInjectiveUp ? (
                      <FontAwesomeIcon
                        icon={faArrowUp}
                        style={{ color: "green" }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        style={{ color: "red" }}
                      />
                    )}
                  </>
                )}
              </td>{" "}
              <td
                style={{
                  color:
                    injectivePriceTomorrow !== "Loading..."
                      ? isInjectiveUp
                        ? "green"
                        : "red"
                      : "black",
                }}
              >
                {" "}
                {injectivePriceTomorrow === "Loading..." ? (
                  "Loading..."
                ) : (
                  <>{isInjectiveUp ? "Going Up" : "Going Down"} </>
                )}
                {injectivePriceTomorrow !== "Loading..." && (
                  <>
                    {isInjectiveUp ? (
                      <FontAwesomeIcon
                        icon={faArrowUp}
                        style={{ color: "green" }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        style={{ color: "red" }}
                      />
                    )}
                  </>
                )}
              </td>
            </tr>
            <tr>
              <td className="fw-bold">
                <h1 style={{ fontSize: "17px" }}>
                  29.{" "}
                  <img
                    src="https://static.crypto.com/token/icons/monero/color_icon.png"
                    width={"20px"}
                    alt="icon"
                  ></img>{" "}
                  Monero Price Tomorrow (XMR)
                </h1>
              </td>
              <td>
                {moneroPriceToday === "Loading..."
                  ? moneroPriceToday
                  : `$${moneroPriceToday}`}{" "}
              </td>
              <td
                style={{
                  color:
                    moneroPriceTomorrow !== "Loading..."
                      ? isMoneroUp
                        ? "green"
                        : "red"
                      : "black",
                }}
              >
                {moneroPriceTomorrow === "Loading..."
                  ? moneroPriceTomorrow
                  : `$${moneroPriceTomorrow}`}{" "}
                {moneroPriceTomorrow !== "Loading..." && (
                  <>
                    {isMoneroUp ? (
                      <FontAwesomeIcon
                        icon={faArrowUp}
                        style={{ color: "green" }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        style={{ color: "red" }}
                      />
                    )}
                  </>
                )}
              </td>{" "}
              <td
                style={{
                  color:
                    moneroPriceTomorrow !== "Loading..."
                      ? isMoneroUp
                        ? "green"
                        : "red"
                      : "black",
                }}
              >
                {" "}
                {moneroPriceTomorrow === "Loading..." ? (
                  "Loading..."
                ) : (
                  <>{isMoneroUp ? "Going Up" : "Going Down"} </>
                )}
                {moneroPriceTomorrow !== "Loading..." && (
                  <>
                    {isMoneroUp ? (
                      <FontAwesomeIcon
                        icon={faArrowUp}
                        style={{ color: "green" }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        style={{ color: "red" }}
                      />
                    )}
                  </>
                )}
              </td>
            </tr>
            <tr>
              <td className="fw-bold">
                <h1 style={{ fontSize: "17px" }}>
                  30.{" "}
                  <img
                    src="https://static.crypto.com/token/icons/aptos/color_icon.png"
                    width={"20px"}
                    alt="icon"
                  ></img>{" "}
                  Aptos Price Tomorrow (APT)
                </h1>
              </td>
              <td>
                {aptosPriceToday === "Loading..."
                  ? aptosPriceToday
                  : `$${aptosPriceToday}`}{" "}
              </td>
              <td
                style={{
                  color:
                    aptosPriceTomorrow !== "Loading..."
                      ? isAptosUp
                        ? "green"
                        : "red"
                      : "black",
                }}
              >
                {aptosPriceTomorrow === "Loading..."
                  ? aptosPriceTomorrow
                  : `$${aptosPriceTomorrow}`}{" "}
                {aptosPriceTomorrow !== "Loading..." && (
                  <>
                    {isAptosUp ? (
                      <FontAwesomeIcon
                        icon={faArrowUp}
                        style={{ color: "green" }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        style={{ color: "red" }}
                      />
                    )}
                  </>
                )}
              </td>{" "}
              <td
                style={{
                  color:
                    aptosPriceTomorrow !== "Loading..."
                      ? isAptosUp
                        ? "green"
                        : "red"
                      : "black",
                }}
              >
                {" "}
                {aptosPriceTomorrow === "Loading..." ? (
                  "Loading..."
                ) : (
                  <>{isAptosUp ? "Going Up" : "Going Down"} </>
                )}
                {aptosPriceTomorrow !== "Loading..." && (
                  <>
                    {isAptosUp ? (
                      <FontAwesomeIcon
                        icon={faArrowUp}
                        style={{ color: "green" }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        style={{ color: "red" }}
                      />
                    )}
                  </>
                )}
              </td>
            </tr>
            <tr>
              <td className="fw-bold">
                <h1 style={{ fontSize: "17px" }}>
                  31.{" "}
                  <img
                    src="https://static.crypto.com/token/icons/ethereum-classic/color_icon.png"
                    width={"20px"}
                    alt="icon"
                  ></img>{" "}
                  Ethereum Classic Price Tomorrow (ETC)
                </h1>
              </td>
              <td>
                {ethereumClassicPriceToday === "Loading..."
                  ? ethereumClassicPriceToday
                  : `$${ethereumClassicPriceToday}`}{" "}
              </td>
              <td
                style={{
                  color:
                    ethereumClassicPriceTomorrow !== "Loading..."
                      ? isEthereumClassicUp
                        ? "green"
                        : "red"
                      : "black",
                }}
              >
                {ethereumClassicPriceTomorrow === "Loading..."
                  ? ethereumClassicPriceTomorrow
                  : `$${ethereumClassicPriceTomorrow}`}{" "}
                {ethereumClassicPriceTomorrow !== "Loading..." && (
                  <>
                    {isEthereumClassicUp ? (
                      <FontAwesomeIcon
                        icon={faArrowUp}
                        style={{ color: "green" }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        style={{ color: "red" }}
                      />
                    )}
                  </>
                )}
              </td>{" "}
              <td
                style={{
                  color:
                    ethereumClassicPriceTomorrow !== "Loading..."
                      ? isEthereumClassicUp
                        ? "green"
                        : "red"
                      : "black",
                }}
              >
                {" "}
                {ethereumClassicPriceTomorrow === "Loading..." ? (
                  "Loading..."
                ) : (
                  <>{isEthereumClassicUp ? "Going Up" : "Going Down"} </>
                )}
                {ethereumClassicPriceTomorrow !== "Loading..." && (
                  <>
                    {isEthereumClassicUp ? (
                      <FontAwesomeIcon
                        icon={faArrowUp}
                        style={{ color: "green" }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        style={{ color: "red" }}
                      />
                    )}
                  </>
                )}
              </td>
            </tr>
            <tr>
              <td className="fw-bold">
                <h1 style={{ fontSize: "17px" }}>
                  32.{" "}
                  <img
                    src="https://static.crypto.com/token/icons/hedera/color_icon.png"
                    width={"20px"}
                    alt="icon"
                  ></img>{" "}
                  Hedera Price Tomorrow (HBR)
                </h1>
              </td>
              <td>
                {hederaPriceToday === "Loading..."
                  ? hederaPriceToday
                  : `$${hederaPriceToday}`}{" "}
              </td>
              <td
                style={{
                  color:
                    hederaPriceTomorrow !== "Loading..."
                      ? isHederaUp
                        ? "green"
                        : "red"
                      : "black",
                }}
              >
                {hederaPriceTomorrow === "Loading..."
                  ? hederaPriceTomorrow
                  : `$${hederaPriceTomorrow}`}{" "}
                {hederaPriceTomorrow !== "Loading..." && (
                  <>
                    {isHederaUp ? (
                      <FontAwesomeIcon
                        icon={faArrowUp}
                        style={{ color: "green" }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        style={{ color: "red" }}
                      />
                    )}
                  </>
                )}
              </td>{" "}
              <td
                style={{
                  color:
                    hederaPriceTomorrow !== "Loading..."
                      ? isHederaUp
                        ? "green"
                        : "red"
                      : "black",
                }}
              >
                {" "}
                {hederaPriceTomorrow === "Loading..." ? (
                  "Loading..."
                ) : (
                  <>{isHederaUp ? "Going Up" : "Going Down"} </>
                )}
                {hederaPriceTomorrow !== "Loading..." && (
                  <>
                    {isHederaUp ? (
                      <FontAwesomeIcon
                        icon={faArrowUp}
                        style={{ color: "green" }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        style={{ color: "red" }}
                      />
                    )}
                  </>
                )}
              </td>
            </tr>
            <tr>
              <td className="fw-bold">
                <h1 style={{ fontSize: "17px" }}>
                  33.{" "}
                  <img
                    src="https://static.crypto.com/token/icons/immutable-x/color_icon.png"
                    width={"20px"}
                    alt="icon"
                  ></img>{" "}
                  Immutable X Price Tomorrow (IMX)
                </h1>
              </td>
              <td>
                {/* You can replace the placeholder text below with your logic for IMX Price Today */}
                {immutableXPriceToday === "Loading..."
                  ? immutableXPriceToday
                  : `$${immutableXPriceToday}`}{" "}
              </td>
              <td
                style={{
                  color:
                    immutableXPriceTomorrow !== "Loading..."
                      ? isImmutableXUp
                        ? "green"
                        : "red"
                      : "black",
                }}
              >
                {immutableXPriceTomorrow === "Loading..."
                  ? immutableXPriceTomorrow
                  : `$${immutableXPriceTomorrow}`}{" "}
                {immutableXPriceTomorrow !== "Loading..." && (
                  <>
                    {isImmutableXUp ? (
                      <FontAwesomeIcon
                        icon={faArrowUp}
                        style={{ color: "green" }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        style={{ color: "red" }}
                      />
                    )}
                  </>
                )}
              </td>{" "}
              <td
                style={{
                  color:
                    immutableXPriceToday !== "Loading..."
                      ? isImmutableXUp
                        ? "green"
                        : "red"
                      : "black",
                }}
              >
                {" "}
                {immutableXPriceTomorrow === "Loading..." ? (
                  "Loading..."
                ) : (
                  <>{isImmutableXUp ? "Going Up" : "Going Down"} </>
                )}
                {immutableXPriceTomorrow !== "Loading..." && (
                  <>
                    {isImmutableXUp ? (
                      <FontAwesomeIcon
                        icon={faArrowUp}
                        style={{ color: "green" }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        style={{ color: "red" }}
                      />
                    )}
                  </>
                )}
              </td>
            </tr>
            <tr>
              <td className="fw-bold">
                <h1 style={{ fontSize: "17px" }}>
                  34.{" "}
                  <img
                    src="https://static.crypto.com/token/icons/optimism-ethereum/color_icon.png"
                    width={"20px"}
                    alt="icon"
                  ></img>{" "}
                  Optimism Price Tomorrow (OP)
                </h1>
              </td>
              <td>
                {optimismPriceToday === "Loading..."
                  ? optimismPriceToday
                  : `$${optimismPriceToday}`}{" "}
              </td>
              <td
                style={{
                  color:
                    optimismPriceTomorrow !== "Loading..."
                      ? isOptimismUp
                        ? "green"
                        : "red"
                      : "black",
                }}
              >
                {optimismPriceTomorrow === "Loading..."
                  ? optimismPriceTomorrow
                  : `$${optimismPriceTomorrow}`}{" "}
                {optimismPriceTomorrow !== "Loading..." && (
                  <>
                    {isOptimismUp ? (
                      <FontAwesomeIcon
                        icon={faArrowUp}
                        style={{ color: "green" }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        style={{ color: "red" }}
                      />
                    )}
                  </>
                )}
              </td>{" "}
              <td
                style={{
                  color:
                    optimismPriceTomorrow !== "Loading..."
                      ? isOptimismUp
                        ? "green"
                        : "red"
                      : "black",
                }}
              >
                {" "}
                {optimismPriceTomorrow === "Loading..." ? (
                  "Loading..."
                ) : (
                  <>{isOptimismUp ? "Going Up" : "Going Down"} </>
                )}
                {optimismPriceTomorrow !== "Loading..." && (
                  <>
                    {isOptimismUp ? (
                      <FontAwesomeIcon
                        icon={faArrowUp}
                        style={{ color: "green" }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        style={{ color: "red" }}
                      />
                    )}
                  </>
                )}
              </td>
            </tr>
            <tr>
              <td className="fw-bold">
                <h1 style={{ fontSize: "17px" }}>
                  35.{" "}
                  <img
                    src="https://static.crypto.com/token/icons/filecoin/color_icon.png"
                    width={"20px"}
                    alt="icon"
                  ></img>{" "}
                  Filecoin Price Tomorrow (FIL)
                </h1>
              </td>
              <td>
                {filecoinPriceToday === "Loading..."
                  ? filecoinPriceToday
                  : `$${filecoinPriceToday}`}{" "}
              </td>
              <td
                style={{
                  color:
                    filecoinPriceTomorrow !== "Loading..."
                      ? isFilecoinUp
                        ? "green"
                        : "red"
                      : "black",
                }}
              >
                {filecoinPriceTomorrow === "Loading..."
                  ? filecoinPriceTomorrow
                  : `$${filecoinPriceTomorrow}`}{" "}
                {filecoinPriceTomorrow !== "Loading..." && (
                  <>
                    {isFilecoinUp ? (
                      <FontAwesomeIcon
                        icon={faArrowUp}
                        style={{ color: "green" }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        style={{ color: "red" }}
                      />
                    )}
                  </>
                )}
              </td>{" "}
              <td
                style={{
                  color:
                    filecoinPriceTomorrow !== "Loading..."
                      ? isFilecoinUp
                        ? "green"
                        : "red"
                      : "black",
                }}
              >
                {" "}
                {filecoinPriceTomorrow === "Loading..." ? (
                  "Loading..."
                ) : (
                  <>{isFilecoinUp ? "Going Up" : "Going Down"} </>
                )}
                {filecoinPriceTomorrow !== "Loading..." && (
                  <>
                    {isFilecoinUp ? (
                      <FontAwesomeIcon
                        icon={faArrowUp}
                        style={{ color: "green" }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        style={{ color: "red" }}
                      />
                    )}
                  </>
                )}
              </td>
            </tr>
            {/* <NewsletterSubscription /> */}
            <tr>
              <td className="fw-bold">
                <h1 style={{ fontSize: "17px" }}>
                  36.{" "}
                  <img
                    src="https://static.crypto.com/token/icons/vechain/color_icon.png"
                    width={"20px"}
                    alt="icon"
                  ></img>{" "}
                  VeChain Price Tomorrow (VET)
                </h1>
              </td>
              <td>
                {veChainPriceToday === "Loading..."
                  ? veChainPriceToday
                  : `$${veChainPriceToday}`}{" "}
              </td>
              <td
                style={{
                  color:
                    veChainPriceTomorrow !== "Loading..."
                      ? isVeChainUp
                        ? "green"
                        : "red"
                      : "black",
                }}
              >
                {veChainPriceTomorrow === "Loading..."
                  ? veChainPriceTomorrow
                  : `$${veChainPriceTomorrow}`}{" "}
                {veChainPriceTomorrow !== "Loading..." && (
                  <>
                    {isVeChainUp ? (
                      <FontAwesomeIcon
                        icon={faArrowUp}
                        style={{ color: "green" }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        style={{ color: "red" }}
                      />
                    )}
                  </>
                )}
              </td>{" "}
              <td
                style={{
                  color:
                    veChainPriceTomorrow !== "Loading..."
                      ? isVeChainUp
                        ? "green"
                        : "red"
                      : "black",
                }}
              >
                {" "}
                {veChainPriceTomorrow === "Loading..." ? (
                  "Loading..."
                ) : (
                  <>{isVeChainUp ? "Going Up" : "Going Down"} </>
                )}
                {veChainPriceTomorrow !== "Loading..." && (
                  <>
                    {isVeChainUp ? (
                      <FontAwesomeIcon
                        icon={faArrowUp}
                        style={{ color: "green" }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        style={{ color: "red" }}
                      />
                    )}
                  </>
                )}
              </td>
            </tr>
            <tr>
              <td className="fw-bold">
                <h1 style={{ fontSize: "17px" }}>
                  37.{" "}
                  <img
                    src="https://static.crypto.com/token/icons/crypto-com-coin/color_icon.png"
                    width={"20px"}
                    alt="icon"
                  ></img>{" "}
                  Cronos Price Tomorrow (CRO)
                </h1>
              </td>
              <td>
                {cronosPriceToday === "Loading..."
                  ? cronosPriceToday
                  : `$${cronosPriceToday}`}{" "}
              </td>
              <td
                style={{
                  color:
                    cronosPriceTomorrow !== "Loading..."
                      ? isCronosUp
                        ? "green"
                        : "red"
                      : "black",
                }}
              >
                {cronosPriceTomorrow === "Loading..."
                  ? cronosPriceTomorrow
                  : `$${cronosPriceTomorrow}`}{" "}
                {cronosPriceTomorrow !== "Loading..." && (
                  <>
                    {isCronosUp ? (
                      <FontAwesomeIcon
                        icon={faArrowUp}
                        style={{ color: "green" }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        style={{ color: "red" }}
                      />
                    )}
                  </>
                )}
              </td>{" "}
              <td
                style={{
                  color:
                    cronosPriceTomorrow !== "Loading..."
                      ? isCronosUp
                        ? "green"
                        : "red"
                      : "black",
                }}
              >
                {" "}
                {cronosPriceTomorrow === "Loading..." ? (
                  "Loading..."
                ) : (
                  <>{isCronosUp ? "Going Up" : "Going Down"} </>
                )}
                {cronosPriceTomorrow !== "Loading..." && (
                  <>
                    {isCronosUp ? (
                      <FontAwesomeIcon
                        icon={faArrowUp}
                        style={{ color: "green" }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        style={{ color: "red" }}
                      />
                    )}
                  </>
                )}
              </td>
            </tr>
            <tr>
              <td className="fw-bold">
                <h1 style={{ fontSize: "17px" }}>
                  38.{" "}
                  <img
                    src="https://static.crypto.com/token/icons/trueusd/color_icon.png"
                    width={"20px"}
                    alt="icon"
                  ></img>{" "}
                  TrueUSD Price Tomorrow (TUSD)
                </h1>
              </td>
              <td>
                {trueUsdPriceToday === "Loading..."
                  ? trueUsdPriceToday
                  : `$${trueUsdPriceToday}`}{" "}
              </td>
              <td
                style={{
                  color:
                    trueUsdPriceTomorrow !== "Loading..."
                      ? isTrueUsdUp
                        ? "green"
                        : "red"
                      : "black",
                }}
              >
                {trueUsdPriceTomorrow === "Loading..."
                  ? trueUsdPriceTomorrow
                  : `$${trueUsdPriceTomorrow}`}{" "}
                {trueUsdPriceTomorrow !== "Loading..." && (
                  <>
                    {isTrueUsdUp ? (
                      <FontAwesomeIcon
                        icon={faArrowUp}
                        style={{ color: "green" }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        style={{ color: "red" }}
                      />
                    )}
                  </>
                )}
              </td>{" "}
              <td
                style={{
                  color:
                    trueUsdPriceTomorrow !== "Loading..."
                      ? isTrueUsdUp
                        ? "green"
                        : "red"
                      : "black",
                }}
              >
                {" "}
                {trueUsdPriceTomorrow === "Loading..." ? (
                  "Loading..."
                ) : (
                  <>{isTrueUsdUp ? "Going Up" : "Going Down"} </>
                )}
                {trueUsdPriceTomorrow !== "Loading..." && (
                  <>
                    {isTrueUsdUp ? (
                      <FontAwesomeIcon
                        icon={faArrowUp}
                        style={{ color: "green" }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        style={{ color: "red" }}
                      />
                    )}
                  </>
                )}
              </td>
            </tr>
            <tr>
              <td className="fw-bold">
                <h1 style={{ fontSize: "17px" }}>
                  39.{" "}
                  <img
                    src="https://static.crypto.com/token/icons/kaspa/color_icon.png"
                    width={"20px"}
                    alt="icon"
                  ></img>{" "}
                  Kaspa Price Tomorrow (KAS)
                </h1>
              </td>
              <td>
                {kaspaPriceToday === "Loading..."
                  ? kaspaPriceToday
                  : `$${kaspaPriceToday}`}{" "}
              </td>
              <td
                style={{
                  color:
                    kaspaPriceTomorrow !== "Loading..."
                      ? isKaspaUp
                        ? "green"
                        : "red"
                      : "black",
                }}
              >
                {kaspaPriceTomorrow === "Loading..."
                  ? kaspaPriceTomorrow
                  : `$${kaspaPriceTomorrow}`}{" "}
                {kaspaPriceTomorrow !== "Loading..." && (
                  <>
                    {isKaspaUp ? (
                      <FontAwesomeIcon
                        icon={faArrowUp}
                        style={{ color: "green" }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        style={{ color: "red" }}
                      />
                    )}
                  </>
                )}
              </td>{" "}
              <td
                style={{
                  color:
                    kaspaPriceTomorrow !== "Loading..."
                      ? isKaspaUp
                        ? "green"
                        : "red"
                      : "black",
                }}
              >
                {" "}
                {kaspaPriceTomorrow === "Loading..." ? (
                  "Loading..."
                ) : (
                  <>{isKaspaUp ? "Going Up" : "Going Down"} </>
                )}
                {kaspaPriceTomorrow !== "Loading..." && (
                  <>
                    {isKaspaUp ? (
                      <FontAwesomeIcon
                        icon={faArrowUp}
                        style={{ color: "green" }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        style={{ color: "red" }}
                      />
                    )}
                  </>
                )}
              </td>
            </tr>
            <tr>
              <td className="fw-bold">
                <h1 style={{ fontSize: "17px" }}>
                  40.{" "}
                  <img
                    src="https://static.crypto.com/token/icons/lido-dao/color_icon.png"
                    width={"20px"}
                    alt="icon"
                  ></img>{" "}
                  Lido DAO Token Price Tomorrow (LDO)
                </h1>
              </td>
              <td>
                {lidoDaoTokenPriceToday === "Loading..."
                  ? lidoDaoTokenPriceToday
                  : `$${lidoDaoTokenPriceToday}`}{" "}
              </td>
              <td
                style={{
                  color:
                    lidoDaoTokenPriceTomorrow !== "Loading..."
                      ? isLidoDaoTokenUp
                        ? "green"
                        : "red"
                      : "black",
                }}
              >
                {lidoDaoTokenPriceTomorrow === "Loading..."
                  ? lidoDaoTokenPriceTomorrow
                  : `$${lidoDaoTokenPriceTomorrow}`}{" "}
                {lidoDaoTokenPriceTomorrow !== "Loading..." && (
                  <>
                    {isLidoDaoTokenUp ? (
                      <FontAwesomeIcon
                        icon={faArrowUp}
                        style={{ color: "green" }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        style={{ color: "red" }}
                      />
                    )}
                  </>
                )}
              </td>{" "}
              <td
                style={{
                  color:
                    lidoDaoTokenPriceTomorrow !== "Loading..."
                      ? isLidoDaoTokenUp
                        ? "green"
                        : "red"
                      : "black",
                }}
              >
                {" "}
                {lidoDaoTokenPriceTomorrow === "Loading..." ? (
                  "Loading..."
                ) : (
                  <>{isLidoDaoTokenUp ? "Going Up" : "Going Down"} </>
                )}
                {lidoDaoTokenPriceTomorrow !== "Loading..." && (
                  <>
                    {isLidoDaoTokenUp ? (
                      <FontAwesomeIcon
                        icon={faArrowUp}
                        style={{ color: "green" }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        style={{ color: "red" }}
                      />
                    )}
                  </>
                )}
              </td>
            </tr>
            <tr>
              <td className="fw-bold">
                <h1 style={{ fontSize: "17px" }}>
                  41.{" "}
                  <img
                    src="https://static.crypto.com/token/icons/stacks/color_icon.png"
                    width={"20px"}
                    alt="icon"
                  ></img>{" "}
                  Stacks Price Tomorrow (STX)
                </h1>
              </td>
              <td>
                {stacksPriceToday === "Loading..."
                  ? stacksPriceToday
                  : `$${stacksPriceToday}`}{" "}
              </td>
              <td
                style={{
                  color:
                    stacksPriceTomorrow !== "Loading..."
                      ? isStacksUp
                        ? "green"
                        : "red"
                      : "black",
                }}
              >
                {stacksPriceTomorrow === "Loading..."
                  ? stacksPriceTomorrow
                  : `$${stacksPriceTomorrow}`}{" "}
                {stacksPriceTomorrow !== "Loading..." && (
                  <>
                    {isStacksUp ? (
                      <FontAwesomeIcon
                        icon={faArrowUp}
                        style={{ color: "green" }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        style={{ color: "red" }}
                      />
                    )}
                  </>
                )}
              </td>{" "}
              <td
                style={{
                  color:
                    stacksPriceTomorrow !== "Loading..."
                      ? isStacksUp
                        ? "green"
                        : "red"
                      : "black",
                }}
              >
                {" "}
                {stacksPriceTomorrow === "Loading..." ? (
                  "Loading..."
                ) : (
                  <>{isStacksUp ? "Going Up" : "Going Down"} </>
                )}
                {stacksPriceTomorrow !== "Loading..." && (
                  <>
                    {isStacksUp ? (
                      <FontAwesomeIcon
                        icon={faArrowUp}
                        style={{ color: "green" }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        style={{ color: "red" }}
                      />
                    )}
                  </>
                )}
              </td>
            </tr>
            <tr>
              <td className="fw-bold">
                <h1 style={{ fontSize: "17px" }}>
                  42.{" "}
                  <img
                    src="https://static.crypto.com/token/icons/celestia/color_icon.png"
                    width={"20px"}
                    alt="icon"
                  ></img>{" "}
                  Celestia Price Tomorrow (TIA)
                </h1>
              </td>
              <td>
                {celestiaPriceToday === "Loading..."
                  ? celestiaPriceToday
                  : `$${celestiaPriceToday}`}{" "}
              </td>
              <td
                style={{
                  color:
                    celestiaPriceTomorrow !== "Loading..."
                      ? isCelestiaUp
                        ? "green"
                        : "red"
                      : "black",
                }}
              >
                {celestiaPriceTomorrow === "Loading..."
                  ? celestiaPriceTomorrow
                  : `$${celestiaPriceTomorrow}`}{" "}
                {celestiaPriceTomorrow !== "Loading..." && (
                  <>
                    {isCelestiaUp ? (
                      <FontAwesomeIcon
                        icon={faArrowUp}
                        style={{ color: "green" }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        style={{ color: "red" }}
                      />
                    )}
                  </>
                )}
              </td>{" "}
              <td
                style={{
                  color:
                    celestiaPriceTomorrow !== "Loading..."
                      ? isCelestiaUp
                        ? "green"
                        : "red"
                      : "black",
                }}
              >
                {" "}
                {celestiaPriceTomorrow === "Loading..." ? (
                  "Loading..."
                ) : (
                  <>{isCelestiaUp ? "Going Up" : "Going Down"} </>
                )}
                {celestiaPriceTomorrow !== "Loading..." && (
                  <>
                    {isCelestiaUp ? (
                      <FontAwesomeIcon
                        icon={faArrowUp}
                        style={{ color: "green" }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        style={{ color: "red" }}
                      />
                    )}
                  </>
                )}
              </td>
            </tr>
            <tr>
              <td className="fw-bold">
                <h1 style={{ fontSize: "17px" }}>
                  43.{" "}
                  <img
                    src="https://static.crypto.com/token/icons/algorand/color_icon.png"
                    width={"20px"}
                    alt="icon"
                  ></img>{" "}
                  Algorand Price Tomorrow (ALGO)
                </h1>
              </td>
              <td>
                {algorandPriceToday === "Loading..."
                  ? algorandPriceToday
                  : `$${algorandPriceToday}`}{" "}
              </td>
              <td
                style={{
                  color:
                    algorandPriceTomorrow !== "Loading..."
                      ? isAlgorandUp
                        ? "green"
                        : "red"
                      : "black",
                }}
              >
                {algorandPriceTomorrow === "Loading..."
                  ? algorandPriceTomorrow
                  : `$${algorandPriceTomorrow}`}{" "}
                {algorandPriceTomorrow !== "Loading..." && (
                  <>
                    {isAlgorandUp ? (
                      <FontAwesomeIcon
                        icon={faArrowUp}
                        style={{ color: "green" }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        style={{ color: "red" }}
                      />
                    )}
                  </>
                )}
              </td>{" "}
              <td
                style={{
                  color:
                    algorandPriceTomorrow !== "Loading..."
                      ? isAlgorandUp
                        ? "green"
                        : "red"
                      : "black",
                }}
              >
                {" "}
                {algorandPriceTomorrow === "Loading..." ? (
                  "Loading..."
                ) : (
                  <>{isAlgorandUp ? "Going Up" : "Going Down"} </>
                )}
                {algorandPriceTomorrow !== "Loading..." && (
                  <>
                    {isAlgorandUp ? (
                      <FontAwesomeIcon
                        icon={faArrowUp}
                        style={{ color: "green" }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        style={{ color: "red" }}
                      />
                    )}
                  </>
                )}
              </td>
            </tr>
            <tr>
              <td className="fw-bold">
                <h1 style={{ fontSize: "17px" }}>
                  44.{" "}
                  <img
                    src="https://static.crypto.com/token/icons/mantle/color_icon.png"
                    width={"20px"}
                    alt="icon"
                  ></img>{" "}
                  Mantle Price Tomorrow (MNT)
                </h1>
              </td>
              <td>
                {mantlePriceToday === "Loading..."
                  ? mantlePriceToday
                  : `$${mantlePriceToday}`}{" "}
              </td>
              <td
                style={{
                  color:
                    mantlePriceTomorrow !== "Loading..."
                      ? isMantleUp
                        ? "green"
                        : "red"
                      : "black",
                }}
              >
                {mantlePriceTomorrow === "Loading..."
                  ? mantlePriceTomorrow
                  : `$${mantlePriceTomorrow}`}{" "}
                {mantlePriceTomorrow !== "Loading..." && (
                  <>
                    {isMantleUp ? (
                      <FontAwesomeIcon
                        icon={faArrowUp}
                        style={{ color: "green" }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        style={{ color: "red" }}
                      />
                    )}
                  </>
                )}
              </td>{" "}
              <td
                style={{
                  color:
                    mantlePriceTomorrow !== "Loading..."
                      ? isMantleUp
                        ? "green"
                        : "red"
                      : "black",
                }}
              >
                {" "}
                {mantlePriceTomorrow === "Loading..." ? (
                  "Loading..."
                ) : (
                  <>{isMantleUp ? "Going Up" : "Going Down"} </>
                )}
                {mantlePriceTomorrow !== "Loading..." && (
                  <>
                    {isMantleUp ? (
                      <FontAwesomeIcon
                        icon={faArrowUp}
                        style={{ color: "green" }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        style={{ color: "red" }}
                      />
                    )}
                  </>
                )}
              </td>
            </tr>
            <tr>
              <td className="fw-bold">
                <h1 style={{ fontSize: "17px" }}>
                  45.{" "}
                  <img
                    src="https://static.crypto.com/token/icons/thorchain/color_icon.png"
                    width={"20px"}
                    alt="icon"
                  ></img>{" "}
                  THORChain Price Tomorrow (RUNE)
                </h1>
              </td>
              <td>
                {thorChainPriceToday === "Loading..."
                  ? thorChainPriceToday
                  : `$${thorChainPriceToday}`}{" "}
              </td>
              <td
                style={{
                  color:
                    thorChainPriceTomorrow !== "Loading..."
                      ? isThorChainUp
                        ? "green"
                        : "red"
                      : "black",
                }}
              >
                {thorChainPriceTomorrow === "Loading..."
                  ? thorChainPriceTomorrow
                  : `$${thorChainPriceTomorrow}`}{" "}
                {thorChainPriceTomorrow !== "Loading..." && (
                  <>
                    {isThorChainUp ? (
                      <FontAwesomeIcon
                        icon={faArrowUp}
                        style={{ color: "green" }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        style={{ color: "red" }}
                      />
                    )}
                  </>
                )}
              </td>{" "}
              <td
                style={{
                  color:
                    thorChainPriceTomorrow !== "Loading..."
                      ? isThorChainUp
                        ? "green"
                        : "red"
                      : "black",
                }}
              >
                {" "}
                {thorChainPriceTomorrow === "Loading..." ? (
                  "Loading..."
                ) : (
                  <>{isThorChainUp ? "Going Up" : "Going Down"} </>
                )}
                {thorChainPriceTomorrow !== "Loading..." && (
                  <>
                    {isThorChainUp ? (
                      <FontAwesomeIcon
                        icon={faArrowUp}
                        style={{ color: "green" }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        style={{ color: "red" }}
                      />
                    )}
                  </>
                )}
              </td>
            </tr>
            <tr>
              <td className="fw-bold">
                <h1 style={{ fontSize: "17px" }}>
                  46.{" "}
                  <img
                    src="https://static.crypto.com/token/icons_v2/elrond-egld/3295-1698379482177.png"
                    width={"20px"}
                    alt="icon"
                  ></img>{" "}
                  MultiversX Price Tomorrow (EGLD)
                </h1>
              </td>
              <td>
                {multiversXPriceToday === "Loading..."
                  ? multiversXPriceToday
                  : `$${multiversXPriceToday}`}{" "}
              </td>
              <td
                style={{
                  color:
                    multiversXPriceTomorrow !== "Loading..."
                      ? isMultiversXUp
                        ? "green"
                        : "red"
                      : "black",
                }}
              >
                {multiversXPriceTomorrow === "Loading..."
                  ? multiversXPriceTomorrow
                  : `$${multiversXPriceTomorrow}`}{" "}
                {multiversXPriceTomorrow !== "Loading..." && (
                  <>
                    {isMultiversXUp ? (
                      <FontAwesomeIcon
                        icon={faArrowUp}
                        style={{ color: "green" }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        style={{ color: "red" }}
                      />
                    )}
                  </>
                )}
              </td>{" "}
              <td
                style={{
                  color:
                    multiversXPriceTomorrow !== "Loading..."
                      ? isMultiversXUp
                        ? "green"
                        : "red"
                      : "black",
                }}
              >
                {" "}
                {multiversXPriceTomorrow === "Loading..." ? (
                  "Loading..."
                ) : (
                  <>{isMultiversXUp ? "Going Up" : "Going Down"} </>
                )}
                {multiversXPriceTomorrow !== "Loading..." && (
                  <>
                    {isMultiversXUp ? (
                      <FontAwesomeIcon
                        icon={faArrowUp}
                        style={{ color: "green" }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        style={{ color: "red" }}
                      />
                    )}
                  </>
                )}
              </td>
              
            </tr>
            <tr>
              <td className="fw-bold">
                <h1 style={{ fontSize: "17px" }}>
                  47.{" "}
                  <img
                    src="https://static.crypto.com/token/icons_v2/render-token/2123-1690963196391.jpg"
                    width={"20px"}
                    alt="icon"
                  ></img>{" "}
                  Render Token Price Tomorrow ($RNDR)
                </h1>
              </td>
              <td>
                {renderTokenPriceToday === "Loading..."
                  ? renderTokenPriceToday
                  : `$${renderTokenPriceToday}`}{" "}
              </td>
              <td
                style={{
                  color:
                    renderTokenPriceTomorrow !== "Loading..."
                      ? isRenderTokenUp
                        ? "green"
                        : "red"
                      : "black",
                }}
              >
                {renderTokenPriceTomorrow === "Loading..."
                  ? renderTokenPriceTomorrow
                  : `$${renderTokenPriceTomorrow}`}{" "}
                {renderTokenPriceTomorrow !== "Loading..." && (
                  <>
                    {isRenderTokenUp ? (
                      <FontAwesomeIcon
                        icon={faArrowUp}
                        style={{ color: "green" }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        style={{ color: "red" }}
                      />
                    )}
                  </>
                )}
              </td>{" "}
              <td
                style={{
                  color:
                    renderTokenPriceTomorrow !== "Loading..."
                      ? isRenderTokenUp
                        ? "green"
                        : "red"
                      : "black",
                }}
              >
                {" "}
                {renderTokenPriceTomorrow === "Loading..." ? (
                  "Loading..."
                ) : (
                  <>{isRenderTokenUp ? "Going Up" : "Going Down"} </>
                )}
                {renderTokenPriceTomorrow !== "Loading..." && (
                  <>
                    {isRenderTokenUp ? (
                      <FontAwesomeIcon
                        icon={faArrowUp}
                        style={{ color: "green" }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        style={{ color: "red" }}
                      />
                    )}
                  </>
                )}
              </td>
            </tr>
            <tr>
              <td className="fw-bold">
                <h1 style={{ fontSize: "17px" }}>
                  48.{" "}
                  <img
                    src="https://static.crypto.com/token/icons/the-graph/color_icon.png"
                    width={"20px"}
                    alt="icon"
                  ></img>{" "}
                  The Graph Price Tomorrow (GRT)
                </h1>
              </td>
              <td>
                {theGraphPriceToday === "Loading..."
                  ? theGraphPriceToday
                  : `$${theGraphPriceToday}`}{" "}
              </td>
              <td
                style={{
                  color:
                    theGraphPriceTomorrow !== "Loading..."
                      ? isTheGraphUp
                        ? "green"
                        : "red"
                      : "black",
                }}
              >
                {theGraphPriceTomorrow === "Loading..."
                  ? theGraphPriceTomorrow
                  : `$${theGraphPriceTomorrow}`}{" "}
                {theGraphPriceTomorrow !== "Loading..." && (
                  <>
                    {isTheGraphUp ? (
                      <FontAwesomeIcon
                        icon={faArrowUp}
                        style={{ color: "green" }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        style={{ color: "red" }}
                      />
                    )}
                  </>
                )}
              </td>{" "}
              <td
                style={{
                  color:
                    theGraphPriceTomorrow !== "Loading..."
                      ? isTheGraphUp
                        ? "green"
                        : "red"
                      : "black",
                }}
              >
                {" "}
                {theGraphPriceTomorrow === "Loading..." ? (
                  "Loading..."
                ) : (
                  <>{isTheGraphUp ? "Going Up" : "Going Down"} </>
                )}
                {theGraphPriceTomorrow !== "Loading..." && (
                  <>
                    {isTheGraphUp ? (
                      <FontAwesomeIcon
                        icon={faArrowUp}
                        style={{ color: "green" }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        style={{ color: "red" }}
                      />
                    )}
                  </>
                )}
              </td>
            </tr>
            <tr>
              <td className="fw-bold">
                <h1 style={{ fontSize: "17px" }}>
                  49.{" "}
                  <img
                    src="https://static.crypto.com/token/icons/first-digital-usd/color_icon.png"
                    width={"20px"}
                    alt="icon"
                  ></img>{" "}
                  First Digital USD Price Tomorrow (FDUSD)
                </h1>
              </td>
              <td>
                {firstDigitalUsdPriceToday === "Loading..."
                  ? firstDigitalUsdPriceToday
                  : `$${firstDigitalUsdPriceToday}`}{" "}
              </td>
              <td
                style={{
                  color:
                    firstDigitalUsdPriceTomorrow !== "Loading..."
                      ? isFirstDigitalUsdUp
                        ? "green"
                        : "red"
                      : "black",
                }}
              >
                {firstDigitalUsdPriceTomorrow === "Loading..."
                  ? firstDigitalUsdPriceTomorrow
                  : `$${firstDigitalUsdPriceTomorrow}`}{" "}
                {firstDigitalUsdPriceTomorrow !== "Loading..." && (
                  <>
                    {isFirstDigitalUsdUp ? (
                      <FontAwesomeIcon
                        icon={faArrowUp}
                        style={{ color: "green" }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        style={{ color: "red" }}
                      />
                    )}
                  </>
                )}
              </td>{" "}
              <td
                style={{
                  color:
                    firstDigitalUsdPriceTomorrow !== "Loading..."
                      ? isFirstDigitalUsdUp
                        ? "green"
                        : "red"
                      : "black",
                }}
              >
                {" "}
                {firstDigitalUsdPriceTomorrow === "Loading..." ? (
                  "Loading..."
                ) : (
                  <>{isFirstDigitalUsdUp ? "Going Up" : "Going Down"} </>
                )}
                {firstDigitalUsdPriceTomorrow !== "Loading..." && (
                  <>
                    {isFirstDigitalUsdUp ? (
                      <FontAwesomeIcon
                        icon={faArrowUp}
                        style={{ color: "green" }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        style={{ color: "red" }}
                      />
                    )}
                  </>
                )}
              </td>
            </tr>
            <tr>
              <td className="fw-bold">
                <h1 style={{ fontSize: "17px" }}>
                  50.{" "}
                  <img
                    src="https://static.crypto.com/token/icons/quant/color_icon.png"
                    width={"20px"}
                    alt="icon"
                  ></img>{" "}
                  Quant Price Tomorrow (QNT)
                </h1>
              </td>
              <td>
                {quantPriceToday === "Loading..."
                  ? quantPriceToday
                  : `$${quantPriceToday}`}{" "}
              </td>
              <td
                style={{
                  color:
                    quantPriceTomorrow !== "Loading..."
                      ? isQuantUp
                        ? "green"
                        : "red"
                      : "black",
                }}
              >
                {quantPriceTomorrow === "Loading..."
                  ? quantPriceTomorrow
                  : `$${quantPriceTomorrow}`}{" "}
                {quantPriceTomorrow !== "Loading..." && (
                  <>
                    {isQuantUp ? (
                      <FontAwesomeIcon
                        icon={faArrowUp}
                        style={{ color: "green" }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        style={{ color: "red" }}
                      />
                    )}
                  </>
                )}
              </td>{" "}
              <td
                style={{
                  color:
                    quantPriceTomorrow !== "Loading..."
                      ? isQuantUp
                        ? "green"
                        : "red"
                      : "black",
                }}
              >
                {" "}
                {quantPriceTomorrow === "Loading..." ? (
                  "Loading..."
                ) : (
                  <>{isQuantUp ? "Going Up" : "Going Down"} </>
                )}
                {quantPriceTomorrow !== "Loading..." && (
                  <>
                    {isQuantUp ? (
                      <FontAwesomeIcon
                        icon={faArrowUp}
                        style={{ color: "green" }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        style={{ color: "red" }}
                      />
                    )}
                  </>
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="container my-4">
        <section>
          <h2>Disclaimer</h2>
          <p>
            While we are committed to delivering accurate and insightful
            predictions, it's crucial to acknowledge the inherent volatility of
            cryptocurrency markets.<br></br>
            Our predictions are based on diligent analysis and expert knowledge,
            but they should not be considered as financial advice.<br></br>
            Cryptocurrency investments carry risks, and users are encouraged to
            conduct their own research and consider their risk tolerance before
            making any financial decisions based on our predictions.
          </p>
        </section>

        <section>
          {/* getting the latest 15 blogs  */}
          <h1 className="text-center" style={{ color: "#001F3F" }}>
            Read Latest Articles & Blogs
          </h1>
          <div className="container my-3">
            {loaded && <Spinner />}
            <div className="row">
              {sortedBlogs.slice(0, 15).map((element) => {
                const id = element._id;
                const image = element.imageLink;
                const title = element.title;
                const introduction = element.introduction;

                const date = formatDate(element.date);

                return (
                  <div className="col-md-4 text-center" key={id}>
                    <NewsItem
                      imageUrl={image === "" ? placeholder : image}
                      title={
                        title +
                        ` (${tomorrowDate(date)})` +
                        " Is it Gonna Rise or Not?"
                      }
                      introduction={introduction}
                      publishedAt={date}
                      id={id}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </section>
        {/* <NewsletterSubscription/> */}
      </div>
    </>
  );
}
