import React, { useEffect, useState } from "react";

export default function Contact() {
  const [hasScrolled, setHasScrolled] = useState(false);
  const [details, setDetails] = useState({
    fullName: "",
    email: "",
    phone: "",
    country: "",
    concern: "",
  });

  const [message, setMessage] = useState("Please Wait...");
  const [display, setDisplay] = useState("none");

  const onChange = (e) => {
    const { name, value } = e.target;
    setDetails({ ...details, [name]: value });
  };

  const submitBtn = async () => {
    // api request for loging in user
    setMessage("Please Wait...")
    setDisplay("block");
    try {
      const host = "http://cryptopredicts.live:1000";
      const data = await fetch(`${host}/api/v1/contact/addContact`, {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: details.fullName,
          email: details.email,
          phone: details.phone,
          country: details.country,
          concern: details.concern,
        }),
      });
      const response = await data.json();
      console.log(response);
      alert(
        "Your concern was sent successfully. We'll get back to you in next 24 to 48 hours"
      );
    } catch (e) {
      console.log(e);
      alert("Some error occurred while sending your message. Please check your connection and try again")
    }
  };

  const alert = (message) => {
    setMessage(`${message}`);
    setTimeout(() => {
      setDisplay("none");
    }, 8000);
  };

  useEffect(() => {
    if (!hasScrolled) {
      // Scroll to the top of the page when the component mounts
      window.scrollTo(0, 0);
      setHasScrolled(true);
    }

    document.title = `Crypto Prediction - Contact Us`;
    // Optionally, we can reset the title when the component is unmounted
    return () => {
      document.title = "Crypto Price Prediction - Crypto Currency Prediction for Tomorrow Prices - Crypto News Daily - Bitcoin Price Tomorrow, Ethereum Price Tomorrow, Dogecoin Price Tomorrow, BNB Price Tomorrow, Thether Price Tomorrow or USDT Price Tomorrow, Cardano Price Tomorrow, Solana Price Tomorrow, XRP Price Tomorrow, Polkadot Price Tomorrow, USD Coin Price Tomorrow and much more...";
    };
  }, [hasScrolled]);

  return (
    <div>
      <div className="container my-3">
        <h1 className="text-center">Contact Us</h1>

        <div className="mb-3">
          <label for="fullName" className="form-label">
            Full Name:
          </label>
          <input
            type="text"
            className="form-control"
            id="fullName"
            name="fullName"
            onChange={onChange}
            value={details.fullName}
            placeholder="Shan Ali Mughal"
          />
        </div>

        <div className="mb-3">
          <label for="email" className="form-label">
            Email Address:
          </label>
          <input
            type="email"
            className="form-control"
            id="email"
            name="email"
            value={details.email}
            onChange={onChange}
            placeholder="abc@example.com"
          />
        </div>

        <div className="mb-3">
          <label for="phone" className="form-label">
            Phone Number:
          </label>
          <input
            type="phone"
            className="form-control"
            id="phone"
            name="phone"
            value={details.phone}
            onChange={onChange}
            placeholder="+1 234*******9"
          />
        </div>

        <div className="mb-3">
          <label for="country" className="form-label">
            Country:
          </label>
          <input
            type="text"
            className="form-control"
            id="country"
            name="country"
            value={details.country}
            onChange={onChange}
            placeholder="United States"
          />
        </div>

        <div className="mb-3">
          <label for="concern" className="form-label">
            Your Concern:
          </label>
          <textarea
            className="form-control"
            id="concern"
            name="concern"
            value={details.concern}
            onChange={onChange}
            rows="5"
            placeholder="Write your concern..."
          ></textarea>
        </div>
        <button type="button" class="btn btn-primary" onClick={submitBtn}>
          Submit
        </button>

        <div
          style={{ display: `${display}` }}
          class="alert alert-primary my-3"
          role="alert"
        >
          {message}
        </div>
      </div>
    </div>
  );
}
