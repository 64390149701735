import React, { useEffect, useState } from "react";

export default function PrivacyPolicy() {
  const [hasScrolled, setHasScrolled] = useState(false);

  useEffect(()=>{
    if (!hasScrolled) {
      // Scroll to the top of the page when the component mounts
      window.scrollTo(0, 0);
      setHasScrolled(true);
    }

    document.title = `Crypto Prediction - Privacy Policy`;
    // Optionally, we can reset the title when the component is unmounted
    return () => {
      document.title = "Crypto Price Prediction - Crypto Currency Prediction for Tomorrow Prices - Crypto News Daily - Bitcoin Price Tomorrow, Ethereum Price Tomorrow, Dogecoin Price Tomorrow, BNB Price Tomorrow, Thether Price Tomorrow or USDT Price Tomorrow, Cardano Price Tomorrow, Solana Price Tomorrow, XRP Price Tomorrow, Polkadot Price Tomorrow, USD Coin Price Tomorrow and much more...";
    };

  }, [hasScrolled])
  return (
    <div>
      <header>
        <h1>Privacy Policy</h1>
      </header>

      <section>
        <p>
          Your privacy is important to us. This Privacy Policy explains how
          CryptoPredicts ("we," "us," or "our") collects, uses, and protects
          your personal information. By using our website, you agree to the
          terms outlined in this policy.
        </p>
      </section>

      <section>
        <h2>Information We Collect</h2>
        <p>
          We may collect personal information that you voluntarily provide while
          using our website, such as your name, email address, and any other
          information you choose to share with us. Additionally, we may
          automatically collect certain non-personal information, such as your
          IP address, browser type, and device information for analytical
          purposes.
        </p>
      </section>

      <section>
        <h2>How We Use Your Information</h2>
        <p>We use the information collected for various purposes, including:</p>
        <ul>
          <li>Providing and maintaining our services</li>
          <li>Improving our website and user experience</li>
          <li>
            Sending periodic emails and updates (if you subscribe to our
            newsletter)
          </li>
          <li>Responding to your inquiries and providing customer support</li>
        </ul>
        <p>
          We do not sell, trade, or otherwise transfer your personal information
          to third parties without your consent, except as described in this
          Privacy Policy.
        </p>
      </section>

      <section>
        <h2>Cookies</h2>
        <p>
          We use cookies to enhance your experience on our website. Cookies are
          small files stored on your device that allow us to track and analyze
          user activity. You can choose to disable cookies in your browser
          settings, but this may affect certain features of our website.
        </p>
      </section>

      <section>
        <h2>Security</h2>
        <p>
          We implement security measures to protect your personal information.
          However, no method of transmission over the internet or electronic
          storage is 100% secure. Therefore, while we strive to use commercially
          acceptable means to protect your personal information, we cannot
          guarantee its absolute security.
        </p>
      </section>

      <section>
        <h2>Changes to This Privacy Policy</h2>
        <p>
          We reserve the right to update or change our Privacy Policy at any
          time. Any changes will be effective immediately upon posting the
          updated Privacy Policy on this page.
        </p>
      </section>

      <section>
        <h2>Contact Us</h2>
        <p>
          If you have any questions or concerns regarding our Privacy Policy,
          please navigate to the Contact section and reach out to us with your
          inquiries.
        </p>
      </section>
    </div>
  );
}
