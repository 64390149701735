import React, { useEffect, useState } from "react";
import NewsItem from "./NewsItem";
import blogSampleImage from "../Images/blog_sample.jpg";
import Spinner from "./Spinner";
export default function News() {
  const [blogs, setBlogs] = useState([]);
  const sortedBlogs = blogs.sort((a, b) => new Date(b.date) - new Date(a.date));
  const [loader, setLoader] = useState(true);
  const [hasScrolled, setHasScrolled] = useState(false);
  const getBlogs = async () => {
    const url = "https://cryptopredicts.live:1000/api/v1/news/getNews";

    try {
      let data = await fetch(url);
      let parsedData = await data.json();
      console.log(parsedData);
      setBlogs(parsedData);
      setLoader(false);
    } catch (exception) {
      console.log(exception);
    }
  };

  const formatDate = (date) => {
    const options = {
      day: "numeric",
      month: "long",
      year: "numeric",
    };

    const formattedDate = new Date(date).toLocaleDateString("en-GB", options);
    return formattedDate;
  };

  const tomorrowDate = (date) => {
    // Convert the input date to a JavaScript Date object
    const originalDate = new Date(date);

    // Add one day to the original date
    const nextDay = new Date(originalDate);
    nextDay.setDate(originalDate.getDate() + 1);

    // Define formatting options
    const options = {
      day: "numeric",
      month: "long",
      year: "numeric",
    };

    // Format the next day's date
    const formattedDate = nextDay.toLocaleDateString("en-GB", options);
    return formattedDate;
  };

  useEffect(() => {
    if (!hasScrolled) {
      // Scroll to the top of the page when the component mounts
      window.scrollTo(0, 0);
      setHasScrolled(true);
    }
    getBlogs();

    document.title = `Crypto Prediction - Blogs & News`;
    // Optionally, we can reset the title when the component is unmounted
    return () => {
      document.title = "Crypto Price Prediction - Crypto Currency Prediction for Tomorrow Prices - Crypto News Daily - Bitcoin Price Tomorrow, Ethereum Price Tomorrow, Dogecoin Price Tomorrow, BNB Price Tomorrow, Thether Price Tomorrow or USDT Price Tomorrow, Cardano Price Tomorrow, Solana Price Tomorrow, XRP Price Tomorrow, Polkadot Price Tomorrow, USD Coin Price Tomorrow and much more...";
    };
  }, [hasScrolled]);

  return (
    <div>
      <div className="container my-3">
        <h1 className="text-center" style={{ color: "#001F3F" }}>
          Latest Blogs & Articles
        </h1>
        {loader && <Spinner />}

        <div className="row my-3">
          {sortedBlogs.map((element) => {
            const id = element._id;
            const image = element.imageLink;
            const title = element.title;
            const introduction = element.introduction;

            const date = formatDate(element.date);

            return (
              <div className="col-md-4 text-center" key={id}>
                <NewsItem
                  imageUrl={image === "" ? blogSampleImage : image}
                  title={
                    title +
                    ` (${tomorrowDate(date)})` +
                    " Is it Gonna Rise or Not?"
                  }
                  introduction={introduction}
                  publishedAt={date}
                  id={id}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
